import React, { useMemo } from 'react'
import clsx from 'clsx'

import HearingStatus from '@components/HearingStatus'
import CaseStatus from '@components/CaseStatus'
import Status from '@components/Status'
import Link from '@components/Link'

import { formatAmount } from '@services/money'
import { getStatusType } from '@services/violation'
import links from '@routes/links'

import styles from './styles.module.scss'
import { ViolationGroupType } from '@typings/enums'
import { formatDate } from '@services/date'

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > {
  groupBy: ViolationGroupType
  violation: Violation
}

const ViolationRow: React.FC<Props> = ({
  violation,
  groupBy,
  className,
  ...props
}) => {
  const { ticketNumber, id } = violation as HiredViolation
  const building = useMemo(() => {
    switch (groupBy) {
      case ViolationGroupType.hearingDate: {
        return (
          <td>
            <p className={styles['address']}>
              {violation.address?.toLowerCase()}
            </p>
          </td>
        )
      }
      default: {
        return null
      }
    }
  }, [groupBy])
  const infractionCode =
    'infractionCode' in violation
      ? violation.infractionCode?.toLowerCase()
      : undefined
  const hearingDate =
    'hearingDate' in violation
      ? formatDate(violation.hearingDate, '/')
      : undefined
  const hearingStatus =
    'hearingStatus' in violation ? (
      <HearingStatus
        type={violation.hearingStatus}
        className={styles['hearing-status']}
      />
    ) : (
      <div className={styles['hearing-status']} />
    )
  const caseStatus =
    'caseStatus' in violation ? (
      <CaseStatus
        type={violation.caseStatus}
        className={styles['case-status']}
      />
    ) : (
      <div className={styles['case-status']} />
    )

  const balance =
    'balance' in violation ? formatAmount(violation.balance) : undefined

  const balanceType =
    'balanceType' in violation ? violation.balanceType : undefined

  const status =
    'paid' in violation ? (
      <Status type={getStatusType(violation)} className={styles['status']} />
    ) : (
      <div className={styles['status']} />
    )

  return (
    <tr
      {...props}
      className={clsx('tr', styles['row'], styles[`row-${groupBy}`], className)}
    >
      {building}
      <td>
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={links.violationDetails(id)}
          className={clsx('blue-link', styles['ticket-number'])}
        >
          {ticketNumber}
        </Link>
      </td>
      <td>
        <p className={styles['infraction-code']}>{infractionCode}</p>
      </td>
      <td>
        <p className={styles['hearing-date']}>{hearingDate}</p>
      </td>
      <td>{hearingStatus}</td>
      <td>{caseStatus}</td>
      <td>
        <p className={styles['balance']}>
          {balance}
          <span className={styles['balance-type']}>{balanceType}</span>
        </p>
      </td>
      <td>{status}</td>
    </tr>
  )
}

export default ViolationRow
