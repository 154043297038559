import { authed, notAuthed } from '@routes/validation'

import Home from '@pages/Home'
import NotFound from '@pages/NotFound'
import Login from '@pages/Login'
import ForgotPassword from '@pages/ForgotPassword'
import ResetPassword from '@pages/ResetPassword'
import HiredViolations from '@pages/Violations/HiredViolations'
import ClosedViolations from '@pages/Violations/ClosedViolations'
import AttendedViolations from '@pages/Violations/AttendedViolations'
import UnhiredViolations from '@pages/Violations/UnhiredViolations'
import ViolationDetails from '@pages/ViolationDetails'
import ViolationRequestReopen from '@pages/ViolationRequestReopen'
import ViolationHearingForm from '@pages/ViolationHearingForm'
import Payments from '@pages/Payments'
import Users from '@pages/Users'
import UserDetails from '@pages/UserDetails'
import Defences from '@pages/Defences'
import DefenceQuizzes from '@pages/DefenceQuizzes'

const pages = {
  Home: authed(Home),

  Login: notAuthed(Login),
  ForgotPassword: notAuthed(ForgotPassword),
  ResetPassword: notAuthed(ResetPassword),

  Defences: authed(Defences),
  DefencesQuizzes: authed(DefenceQuizzes),

  HiredViolations: authed(HiredViolations),
  ClosedViolations: authed(ClosedViolations),
  AttendedViolations: authed(AttendedViolations),
  UnhiredViolations: authed(UnhiredViolations),
  ViolationDetails: authed(ViolationDetails),
  ViolationRequestReopen: authed(ViolationRequestReopen),
  ViolationHearingForm: authed(ViolationHearingForm),

  Payments: authed(Payments),

  Users: authed(Users),
  UserDetails: authed(UserDetails),

  NotFound,
}

export default pages
