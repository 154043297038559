import React, { useRef } from 'react'
import styles from '../../styles.module.scss'
import { ReactSVG } from 'react-svg'
import DragHelper from '@assets/drag-helper.svg'
import clsx from 'clsx'
import { useDrag } from 'react-dnd'

interface Props {
  question: Question
  setQuestion: Function
}

const QuestionItem: React.FC<Props> = ({ question, setQuestion }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { question, type: 'questions' },
    end: (item) => {
      console.log(item)
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.4 : 1

  const questionInput = useRef<HTMLInputElement>(null)
  const answerInputs = question.answers.map(() =>
    useRef<HTMLInputElement>(null)
  )
  const focusInput = (inputRef: React.RefObject<{ focus: () => void }>) => {
    const current = inputRef.current
    if (current) {
      current.focus()
    }
  }
  const blurInput = (inputRef: React.RefObject<{ blur: () => void }>) => {
    const current = inputRef.current
    if (current) {
      current.blur()
    }
  }

  const setName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event
    setQuestion({ ...question, question: value || '' })
  }

  const setAnswer = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value },
    } = event
    const answers = [...question.answers]
    answers[index] = value
    setQuestion({ ...question, answers })
  }

  return (
    <>
      <div
        ref={drag}
        style={{ opacity }}
        className={styles['question-item-edit']}
      >
        <div className={styles['question-item-block']}>
          <p className={styles['question-item-number']}>
            0{question.orderNumber}
          </p>
          <div className={styles['header']}>
            <input
              onMouseEnter={() => focusInput(questionInput)}
              onMouseLeave={() => blurInput(questionInput)}
              ref={questionInput}
              onChange={setName}
              required={true}
              placeholder="Enter your question"
              value={question.question}
            />
          </div>
          <div className={styles['answers']}>
            {question.answers.map((answer, index) => (
              <div key={index} className={styles['answer-item']}>
                <input
                  onMouseEnter={() => focusInput(answerInputs[index])}
                  onMouseLeave={() => blurInput(answerInputs[index])}
                  ref={answerInputs[index]}
                  onChange={(event) => setAnswer(index, event)}
                  required={true}
                  placeholder="Answer"
                  value={answer}
                />
              </div>
            ))}
          </div>
        </div>
        <ReactSVG src={DragHelper} className={clsx('svg', styles['logo'])} />
      </div>
    </>
  )
}

export default QuestionItem
