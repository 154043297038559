import links from '@routes/links'
import { LinkProps } from 'react-router-dom'

const defaultLinks: LinkProps[] = [
  {
    to: links.violations(),
    children: 'Violations',
  },
  {
    to: links.users(),
    children: 'Users',
  },
  {
    to: links.payments(),
    children: 'Payments',
  },
  {
    to: links.defences(),
    children: 'Defences',
  },
]

export default defaultLinks
