import { FormAuthorRoleLabel, formAuthorRoleTypes } from '@typings/enums'

interface RadioInfo {
  value: string
  label: string
}

const authorRoleRadios: RadioInfo[] = formAuthorRoleTypes.map((authorRole) => ({
  value: authorRole,
  label: FormAuthorRoleLabel[authorRole],
}))

export default authorRoleRadios
