import React, { useCallback } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import clsx from 'clsx'

import Input from '@components/Input'
import BigCheckbox from '@components/BigCheckbox'
import UserRoleForm from '@common/UserRoleForm'
import Select, { ValueType } from '@components/Select'

import FormData from './form'
import { StrBoolean, StrToBoolean } from '@typings/enums'
import statesOptions from '@constants/statesOptions'
import { phoneMask } from '@constants/mask'

import sharedStyles from '../styles.module.scss'

interface Props {
  form: FormData
  errors: FormikErrors<FormData>
  touched: FormikTouched<FormData>
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setFieldValue: <F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F],
    shouldValidate?: boolean
  ) => void
}

const SenderInfo: React.FC<Props> = ({
  form,
  touched,
  errors,
  onChange,
  setFieldValue,
}) => {
  const onStateChange = useCallback(
    (e: ValueType<State>) => setFieldValue('your_state', e, false),
    [setFieldValue]
  )

  return (
    <React.Fragment>
      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            {'Name and address of person completing this form'}
          </h2>
        </div>

        <div
          className={clsx(
            sharedStyles['inputs'],
            sharedStyles['two-columns'],
            sharedStyles['sub-section']
          )}
        >
          <Input
            type="text"
            name="your_first_name"
            value={form.your_first_name}
            onChange={onChange}
            label="Your First Name"
            labelAsterisk={true}
            placeholder="e.g. John"
            required={true}
            error={touched.your_first_name && errors.your_first_name}
          />
          <Input
            type="text"
            name="your_last_name"
            value={form.your_last_name}
            onChange={onChange}
            label="Your Last Name"
            labelAsterisk={true}
            placeholder="e.g. Millere"
            required={true}
            error={touched.your_last_name && errors.your_last_name}
          />
          <Input
            type="tel"
            name="your_phone"
            value={form.your_phone}
            onChange={onChange}
            label="Your Phone"
            labelAsterisk={true}
            placeholder="e.g. +1 (780) 555-1721"
            required={true}
            mask={phoneMask}
            error={touched.your_phone && errors.your_phone}
          />
          <Input
            type="email"
            name="your_email"
            value={form.your_email}
            onChange={onChange}
            label="Your Email"
            labelAsterisk={true}
            placeholder="example@email.com"
            required={true}
            error={touched.your_email && errors.your_email}
          />
          <Input
            type="text"
            name="your_address"
            value={form.your_address}
            onChange={onChange}
            label="Your Address"
            labelAsterisk={true}
            placeholder="Enter Address"
            required={true}
            error={touched.your_address && errors.your_address}
          />
          <Input
            type="text"
            name="your_city"
            value={form.your_city}
            onChange={onChange}
            label="Your City"
            labelAsterisk={true}
            placeholder="Enter City"
            required={true}
            error={touched.your_city && errors.your_city}
          />
          <Select
            value={form.your_state}
            onChange={onStateChange}
            options={statesOptions}
            label="Your State"
            placeholder="Enter State"
            labelAsterisk={true}
            required={true}
            error={touched.your_state && errors.your_state}
          />
          <Input
            type="number"
            name="your_zip_code"
            value={form.your_zip_code}
            onChange={onChange}
            label="Your ZIP Code"
            labelAsterisk={true}
            placeholder="0000"
            required={true}
            error={touched.your_zip_code && errors.your_zip_code}
          />
        </div>
        <div className={sharedStyles['sub-section']}>
          <div
            className={clsx(
              sharedStyles['input-wrapper'],
              !StrToBoolean[form.are_you_respondent] &&
                sharedStyles['with-margin']
            )}
          >
            <label className="input-label">
              {'Are you the person named as the Respondent on the Summons?'}
              <span className="asterisk" />
            </label>
            <div
              className={clsx(
                sharedStyles['inputs'],
                sharedStyles['big-radios']
              )}
            >
              <BigCheckbox
                type="radio"
                name="are_you_respondent"
                value={StrBoolean.true}
                checked={StrToBoolean[form.are_you_respondent]}
                onChange={onChange}
                containerClassName={sharedStyles['big-radio']}
              >
                {'Yes'}
              </BigCheckbox>
              <BigCheckbox
                type="radio"
                name="are_you_respondent"
                value={StrBoolean.false}
                checked={!StrToBoolean[form.are_you_respondent]}
                onChange={onChange}
                containerClassName={sharedStyles['big-radio']}
              >
                {'No'}
              </BigCheckbox>
            </div>
          </div>
          <UserRoleForm
            hide={StrToBoolean[form.are_you_respondent]}
            describes_who_you_are={form.describes_who_you_are}
            name_of_person_asked_to_request={
              form.name_of_person_asked_to_request
            }
            person_relationship_to_respondent={
              form.person_relationship_to_respondent
            }
            are_authorized_to_represent_respondent={
              form.are_authorized_to_represent_respondent
            }
            onChange={onChange}
            errors={errors}
            touched={touched}
            radioLabelClassName={sharedStyles['radio-label']}
            inputWrapperClassName={sharedStyles['input-wrapper']}
            withMarginClassName={sharedStyles['with-margin']}
            inputsClassName={sharedStyles['inputs']}
            bigRadiosClassName={sharedStyles['big-radios']}
            bigRadioClassName={sharedStyles['big-radio']}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default SenderInfo
