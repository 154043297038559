export const home = '/'

export const hiredViolations = '/violations/hired'
export const closedViolations = '/violations/closed'
export const attendedViolations = '/violations/attended'
export const unhiredViolations = '/violations/unhired'
export const violations = hiredViolations
export const violationDetails = '/violations/:id'
export const violationRequestReopen = '/violations/:id/request-reopen'
export const violationHearingForm = '/violations/:id/hearing-form'

export const users = '/users'
export const userDetails = '/users/:id'
export const analysis = '/analysis'
export const payments = '/payments'
export const defences = '/defences'
export const defenceQuizzes = '/defence-quizzes/:id'

export const login = '/login'
export const forgotPassword = '/forgot-password'
export const resetPassword = '/reset-password'

export const anyPath = '*'

const paths = {
  home,

  violations,
  hiredViolations,
  closedViolations,
  attendedViolations,
  unhiredViolations,
  violationDetails,
  violationRequestReopen,
  violationHearingForm,

  users,
  userDetails,
  analysis,
  payments,
  defences,
  defenceQuizzes,

  login,
  forgotPassword,
  resetPassword,

  anyPath,
}

export default paths
