enum State {
  Alabama = 'AL',
  Alaska = 'AK',
  Arizona = 'AZ',
  Arkansas = 'AR',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  Delaware = 'DE',
  DistrictOfColumbia = 'DC',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Iowa = 'IA',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Maine = 'ME',
  Maryland = 'MD',
  Massachusetts = 'MA',
  Michigan = 'MI',
  Minnesota = 'MN',
  Mississippi = 'MS',
  Missouri = 'MO',
  Montana = 'MT',
  Nebraska = 'NE',
  Nevada = 'NV',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  NewYork = 'NY',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Vermont = 'VT',
  Virginia = 'VA',
  Washington = 'WA',
  WestVirginia = 'WV',
  Wisconsin = 'WI',
  Wyoming = 'WY',
}

const StateLabel = {
  [State.Alabama]: 'Alabama',
  [State.Alaska]: 'Alaska',
  [State.Arizona]: 'Arizona',
  [State.Arkansas]: 'Arkansas',
  [State.California]: 'California',
  [State.Colorado]: 'Colorado',
  [State.Connecticut]: 'Connecticut',
  [State.Delaware]: 'Delaware',
  [State.DistrictOfColumbia]: 'District of Columbia',
  [State.Florida]: 'Florida',
  [State.Georgia]: 'Georgia',
  [State.Hawaii]: 'Hawaii',
  [State.Idaho]: 'Idaho',
  [State.Illinois]: 'Illinois',
  [State.Indiana]: 'Indiana',
  [State.Iowa]: 'Iowa',
  [State.Kansas]: 'Kansas',
  [State.Kentucky]: 'Kentucky',
  [State.Louisiana]: 'Louisiana',
  [State.Maine]: 'Maine',
  [State.Maryland]: 'Maryland',
  [State.Massachusetts]: 'Massachusetts',
  [State.Michigan]: 'Michigan',
  [State.Minnesota]: 'Minnesota',
  [State.Mississippi]: 'Mississippi',
  [State.Missouri]: 'Missouri',
  [State.Montana]: 'Montana',
  [State.Nebraska]: 'Nebraska',
  [State.Nevada]: 'Nevada',
  [State.NewHampshire]: 'New Hampshire',
  [State.NewJersey]: 'New Jersey',
  [State.NewMexico]: 'New Mexico',
  [State.NewYork]: 'New York',
  [State.NorthCarolina]: 'North Carolina',
  [State.NorthDakota]: 'North Dakota',
  [State.Ohio]: 'Ohio',
  [State.Oklahoma]: 'Oklahoma',
  [State.Oregon]: 'Oregon',
  [State.Pennsylvania]: 'Pennsylvania',
  [State.RhodeIsland]: 'Rhode Island',
  [State.SouthCarolina]: 'South Carolina',
  [State.SouthDakota]: 'South Dakota',
  [State.Tennessee]: 'Tennessee',
  [State.Texas]: 'Texas',
  [State.Utah]: 'Utah',
  [State.Vermont]: 'Vermont',
  [State.Virginia]: 'Virginia',
  [State.Washington]: 'Washington',
  [State.WestVirginia]: 'West Virginia',
  [State.Wisconsin]: 'Wisconsin',
  [State.Wyoming]: 'Wyoming',
}

const states = [
  State.Alabama,
  State.Alaska,
  State.Arizona,
  State.Arkansas,
  State.California,
  State.Colorado,
  State.Connecticut,
  State.Delaware,
  State.DistrictOfColumbia,
  State.Florida,
  State.Georgia,
  State.Hawaii,
  State.Idaho,
  State.Illinois,
  State.Indiana,
  State.Iowa,
  State.Kansas,
  State.Kentucky,
  State.Louisiana,
  State.Maine,
  State.Maryland,
  State.Massachusetts,
  State.Michigan,
  State.Minnesota,
  State.Mississippi,
  State.Missouri,
  State.Montana,
  State.Nebraska,
  State.Nevada,
  State.NewHampshire,
  State.NewJersey,
  State.NewMexico,
  State.NewYork,
  State.NorthCarolina,
  State.NorthDakota,
  State.Ohio,
  State.Oklahoma,
  State.Oregon,
  State.Pennsylvania,
  State.RhodeIsland,
  State.SouthCarolina,
  State.SouthDakota,
  State.Tennessee,
  State.Texas,
  State.Utah,
  State.Vermont,
  State.Virginia,
  State.Washington,
  State.WestVirginia,
  State.Wisconsin,
  State.Wyoming,
]

export { StateLabel, states }

export default State
