import React, { useCallback, useEffect } from 'react'

import { RouteComponentProps } from 'react-router-dom'

import View from './view'
import { ConnectedProps, connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import infractionCodesActions from '@redux/infractionCodes/actions'
import { emptyFilter, emptySort } from '@services/user'
import PagingFooter from '@common/PagingFooter'

const mapStateToProps = (state: ReduxState) => ({
  infractionCodes: state.infractionCodes.infractionCodes,
  isLoading: state.infractionCodes.isLoading,
  page: state.infractionCodes.params.page,
  perPage: state.infractionCodes.params.per_page,
  totalPages: state.infractionCodes.params.totalPages,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  infractionCodesActions: bindActionCreators<
    typeof infractionCodesActions,
    BindedAsyncActions<typeof infractionCodesActions>
  >(infractionCodesActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type Props = ConnectedProps<typeof connector> & RouteComponentProps

const Defences: React.FC<Props> = ({
  infractionCodes,
  infractionCodesActions,
  isLoading,
  page,
  perPage,
  totalPages,
}) => {
  useEffect(() => {
    infractionCodesActions.load(
      { page: 1, per_page: -1, ...emptyFilter, ...emptySort },
      true
    )
  }, [infractionCodesActions.load])
  const load = useCallback(
    (params: PagingParams) => infractionCodesActions.load({ ...params }, true),
    [infractionCodesActions.load]
  )
  return (
    <View
      infractionCodes={infractionCodes}
      pagination={
        <PagingFooter
          availablePerPage={[20, 40, 60]}
          isLoading={isLoading}
          page={page || 0}
          perPage={perPage || 0}
          totalPages={totalPages || 0}
          onChange={load}
        />
      }
    />
  )
}

export default connector(Defences)
