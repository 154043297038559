import { FormikErrors } from 'formik'
import { requiredValidate, validate } from '@services/validation'

interface FormData {
  why_should_be_dismissed: string
}

export const formInitial: FormData = {
  why_should_be_dismissed: '',
}

export const validateForm = ({
  why_should_be_dismissed,
}: FormData): FormikErrors<FormData> => ({
  why_should_be_dismissed: validate(why_should_be_dismissed, requiredValidate),
})

export const formKeys: (keyof FormData)[] = ['why_should_be_dismissed']

export default FormData
