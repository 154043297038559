import { useMemo } from 'react'
import { FormikHelpers, useFormik } from 'formik'

import FormData from './form'

import RespondentInfoFormData, {
  formInitial as respondentInfoFormInitial,
  formKeys as respondentInfoFormKeys,
  validateForm as respondentInfoValidate,
} from './RespondentInfo/form'
import SenderInfoFormData, {
  formInitial as senderInfoFormInitial,
  formKeys as senderInfoFormKeys,
  validateForm as senderInfoValidate,
} from './SenderInfo/form'
import ReasonableExcuseFormData, {
  formInitial as reasonableExcuseFormInitial,
  formKeys as reasonableExcuseFormKeys,
  validateForm as reasonableExcuseValidate,
} from './ReasonableExcuse/form'
import FilesFormData, {
  formInitial as filesFormInitial,
  formKeys as filesFormKeys,
  validateForm as filesValidate,
} from './Files/form'

import { pick, pickValues } from '@services/utils'
import { getFormErrors } from '@services/error'
import { toast } from 'react-toastify'

interface Form extends Formik<FormData> {
  respondentInfoForm: RespondentInfoFormData
  senderInfoForm: SenderInfoFormData
  reasonableExcuseForm: ReasonableExcuseFormData
  filesForm: FilesFormData
}

const useForm = (
  violation: ViolationDetails | null,
  user: WebUser | null,
  onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void
): Form => {
  const formik = useFormik<FormData>({
    initialValues: {
      ...respondentInfoFormInitial(violation),
      ...senderInfoFormInitial(user, violation),
      ...reasonableExcuseFormInitial,
      ...filesFormInitial,
    },
    onSubmit: async (
      values: FormData,
      formikHelpers: FormikHelpers<FormData>
    ) => {
      const formErrors = getFormErrors<FormData>({
        ...respondentInfoValidate(values),
        ...senderInfoValidate(values),
        ...reasonableExcuseValidate(values),
        ...filesValidate(values),
      })
      if (
        formErrors &&
        Object.keys(formErrors).length !== 0 &&
        formErrors.constructor === Object
      ) {
        await formikHelpers.setErrors(formErrors)
        return toast('Please fill all required fields')
      }
      onSubmit(values, formikHelpers)
    },
  })

  const { values } = formik

  const respondentInfoForm = useMemo(
    () => pick(values, respondentInfoFormKeys) as RespondentInfoFormData,
    pickValues(values, respondentInfoFormKeys)
  )

  const senderInfoForm = useMemo(
    () => pick(values, senderInfoFormKeys) as SenderInfoFormData,
    pickValues(values, senderInfoFormKeys)
  )

  const reasonableExcuseForm = useMemo(
    () => pick(values, reasonableExcuseFormKeys) as ReasonableExcuseFormData,
    pickValues(values, reasonableExcuseFormKeys)
  )

  const filesForm = useMemo(
    () => pick(values, filesFormKeys) as FilesFormData,
    pickValues(values, filesFormKeys)
  )

  return {
    ...formik,
    respondentInfoForm,
    senderInfoForm,
    reasonableExcuseForm,
    filesForm,
  }
}

export default useForm
