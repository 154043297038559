import React, { useMemo } from 'react'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'
import { ValueType } from 'react-select'

import Select, { OptionType } from '@components/Select'

import { defaultPerPage } from '@config'

import ArrowIcon from '@assets/arrow.svg'

import styles from './styles.module.scss'

interface Props {
  availablePerPage: number[]
  page: number
  perPage: number
  totalPages: number
  isLoading: boolean
  onChange?: (params: PagingParams) => void
}

const PagingFooter: React.FC<Props> = ({
  availablePerPage,
  page,
  perPage,
  totalPages,
  isLoading,
  onChange,
}) => {
  const perPageOptions = useMemo(
    () =>
      availablePerPage.map((value) => ({
        value: String(value),
        label: String(value),
      })),
    []
  )

  const perPageValue = perPageOptions.find((p) => p.value === String(perPage))

  const prevPage = () => onChange?.({ page: page - 1 })
  const prevDisabled = isLoading || page === 1

  const nextPage = () => onChange?.({ page: page + 1 })
  const nextDisabled = isLoading || page === totalPages

  const onPerPageChange = (value: ValueType<OptionType>) => {
    const newPerPage = Number((value as OptionType)?.value || defaultPerPage)
    const newPage = Math.min(
      page,
      Math.ceil((totalPages * perPage) / newPerPage)
    )
    onChange?.({ per_page: newPerPage, page: newPage })
  }

  return (
    <footer className={styles['paging-footer']}>
      <div className={styles['page']}>
        <p className={styles['page-text']}>
          <span className={styles['current-page']}>{page}</span>
          {' of '}
          <span className={styles['total-pages']}>{totalPages}</span>
        </p>
        <button
          className={styles['control-button']}
          onClick={prevPage}
          disabled={prevDisabled}
        >
          <ReactSVG
            src={ArrowIcon}
            className={clsx(
              'svg',
              styles['arrow-icon'],
              styles['arrow-icon-left']
            )}
          />
        </button>
        <button
          className={styles['control-button']}
          onClick={nextPage}
          disabled={nextDisabled}
        >
          <ReactSVG
            src={ArrowIcon}
            className={clsx(
              'svg',
              styles['arrow-icon'],
              styles['arrow-icon-right']
            )}
          />
        </button>
      </div>
      <div className={styles['per-page']}>
        <p className={styles['per-page-title']}>{'Showing '}</p>
        <Select
          options={perPageOptions}
          small={true}
          value={perPageValue}
          onChange={onPerPageChange}
        />
        <p className={styles['per-page-title']}>{' records '}</p>
      </div>
    </footer>
  )
}

export default PagingFooter
