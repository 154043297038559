import React from 'react'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'

import ViolationInfoTable from '@common/ViolationInfoTable'

import { formatAmount } from '@services/money'

import LocationIcon from '@assets/location.svg'
import StonksUpIcon from '@assets/stonks-up.svg'
import PDFIcon from '@assets/pdf.svg'

import styles from './styles.module.scss'

export interface Parts {
  location?: boolean
  ecbAuth?: boolean
  pdfBeforeSubmissionReopen?: boolean
  pdfAfterSubmissionReopen?: boolean
  pdfBeforeSubmission?: boolean
  pdfAfterSubmission?: boolean
  balance?: boolean
  saniFee?: boolean
  infoTable?: boolean
}

type PartProps<K extends keyof ViolationDetails> = Pick<ViolationDetails, K>
interface PdfSubmissionProps {
  url?: string
  text: string
}

const PdfSubmission: React.FC<PdfSubmissionProps> = ({ url, text }) => (
  <div className={styles['location']}>
    <ReactSVG src={PDFIcon} className={clsx('svg', styles['location-icon'])} />
    <div>
      <a href={url} className={styles['title']}>
        <u>{text}</u>
      </a>
    </div>
  </div>
)

const Location: React.FC<PartProps<'inspectionLocation'>> = ({
  inspectionLocation,
}) => (
  <div className={styles['location']}>
    <ReactSVG
      src={LocationIcon}
      className={clsx('svg', styles['location-icon'])}
    />
    <div className={styles['location-info']}>
      <p className={styles['title']}>{'Inspection Location'}</p>
      <p className={styles['address']}>{inspectionLocation}</p>
    </div>
  </div>
)

const Balance: React.FC<PartProps<'balance'>> = ({ balance }) => (
  <div className={styles['balance']}>
    <p className={styles['title']}>{'Balance'}</p>
    <p className={styles['balance-value']}>
      <sup>{'$ '}</sup>
      {formatAmount(balance, undefined, '')}
    </p>
  </div>
)

const SaniFee: React.FC<PartProps<'dismissalFee'>> = ({ dismissalFee }) =>
  dismissalFee ? (
    <div className={styles['sanifee']}>
      <div className={styles['fee-info']}>
        <p className={styles['fee-value']}>{formatAmount(dismissalFee)}</p>
        <p className={styles['fee-type']}>{'Sanifee'}</p>
      </div>
      <ReactSVG
        src={StonksUpIcon}
        className={clsx('svg', styles['stonks-up-icon'])}
      />
    </div>
  ) : null

const getParts = (
  parts: Parts,
  violation: ViolationDetails
): React.ReactNode => {
  const nodes = []
  parts.location &&
    nodes.push(
      <Location
        inspectionLocation={violation.inspectionLocation}
        key={`inspectionLocation-${violation.inspectionLocation}`}
      />
    )
  parts.ecbAuth &&
    nodes.push(
      <PdfSubmission
        url={violation.ecbAuth}
        text={'Link to submitted ECB Auth'}
        key={`inspectionLocation-${violation.ecbAuth}`}
      />
    )
  parts.pdfBeforeSubmissionReopen &&
    nodes.push(
      <PdfSubmission
        url={violation.formBeforeSubmissionReopen}
        text={'Link to prove of motion (before submission)'}
        key={`inspectionLocation-${violation.formBeforeSubmissionReopen}`}
      />
    )
  parts.pdfAfterSubmissionReopen &&
    nodes.push(
      <PdfSubmission
        url={violation.formAfterSubmissionReopen}
        text={'Link to prove of motion (after submission)'}
        key={`inspectionLocation-${violation.formAfterSubmissionReopen}`}
      />
    )
  parts.pdfBeforeSubmission &&
    nodes.push(
      <PdfSubmission
        url={violation.formBeforeSubmission}
        text={'Link to prove of defence (before submission)'}
        key={`inspectionLocation-${violation.formBeforeSubmission}`}
      />
    )
  parts.pdfAfterSubmission &&
    nodes.push(
      <PdfSubmission
        url={violation.formAfterSubmission}
        text={'Link to prove of defence (after submission)'}
        key={`inspectionLocation-${violation.formAfterSubmission}`}
      />
    )
  parts.balance &&
    nodes.push(
      <Balance
        balance={violation.balance}
        key={`balance-${violation.balance}`}
      />
    )
  parts.saniFee &&
    nodes.push(
      <SaniFee
        dismissalFee={violation.dismissalFee}
        key={`dismissalFee-${violation.dismissalFee}`}
      />
    )
  parts.infoTable &&
    nodes.push(
      <ViolationInfoTable
        violation={violation}
        key={`violation-${violation}`}
      />
    )
  return nodes
}

export default getParts
