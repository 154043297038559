import React from 'react'
import styles from '../styles.module.scss'
import { ReactSVG } from 'react-svg'
import DragHelper from '@assets/drag-helper.svg'
import clsx from 'clsx'
import { useDrag } from 'react-dnd'
import { toast } from 'react-toastify'

interface Props {
  question: Question
  defence: string | null
  handleSaveDefence: Function
  lastDragHoveredElement: unknown
}

const QuestionItem: React.FC<Props> = ({
  question,
  defence,
  handleSaveDefence,
  lastDragHoveredElement,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { question, lastDragHoveredElement, type: 'questions' },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      console.log(item)
      if (dropResult) {
        // const { name, question } = dropResult
        console.log(dropResult)
        // changeItemColumn(question, name === 'defence')
        // @ts-ignore
        const questionIndex = `{Q${item.question.orderNumber}}`
        if (!defence) {
          toast('Enter defense text first')
        }
        if (
          // @ts-ignore
          window.lastDragHovered &&
          defence &&
          !defence?.includes(questionIndex)
        ) {
          const textPosition =
            // @ts-ignore
            window.lastDragHovered.getAttribute('data-index') + 1
          const newDefence = defence.split(' ')
          newDefence.splice(textPosition, 0, questionIndex)
          handleSaveDefence(newDefence.join(' '))
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0.4 : 1

  return (
    <>
      <div style={{ opacity }} className={styles['question-item']}>
        <p className={styles['question-item-number']}>
          0{question.orderNumber}
        </p>
        <div ref={drag} className={styles['question-item-block']}>
          <div className={styles['header']}>
            <ReactSVG
              src={DragHelper}
              className={clsx('svg', styles['logo'])}
            />
            <p>{question.question}</p>
          </div>
          <div className={styles['answers']}>
            {question.answers.map((answer, index) => (
              <div key={index} className={styles['answer-item']}>
                <p>0{index}</p>
                <p>{answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionItem
