import { Dispatch, SetStateAction, useEffect, useState } from 'react'

const useStateProp = <S>(
  initialState: S | (() => S)
): [S, Dispatch<SetStateAction<S>>] => {
  const [state, setState] = useState(initialState)
  useEffect(() => {
    if (initialState !== state) {
      setState(initialState)
    }
  }, [initialState, setState])
  return [state, setState]
}

export default useStateProp
