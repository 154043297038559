import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import View from './view'
import { Dispatch, bindActionCreators } from 'redux'
import infractionCodesActions from '@redux/infractionCodes/actions'
import { ConnectedProps, connect } from 'react-redux'
import { isId } from '@services/utils'
import links from '@routes/links'

const mapStateToProps = (state: ReduxState) => ({
  infractionCode: state.infractionCodes.infractionCodeDetails,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  infractionCodeActions: bindActionCreators<
    typeof infractionCodesActions,
    BindedAsyncActions<typeof infractionCodesActions>
  >(infractionCodesActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

interface NativeProps {
  id: string
  replace: (path: string) => void
  goBack: () => void
}

type Props = NativeProps &
  ConnectedProps<typeof connector> &
  RouteComponentProps<{ id: string }>

const DefenceQuizzes: React.FC<Props> = ({
  match: {
    params: { id },
  },
  infractionCode,
  infractionCodeActions: {
    loadDetails,
    saveInfractionCode,
    saveQuiz,
    deleteQuiz,
  },
  history: { replace, goBack },
}) => {
  console.log('tests1')
  useEffect(() => {
    console.log('tests2')
    if (isId(id)) {
      loadDetails(Number(id))
    } else {
      replace(links.home())
    }
  }, [id, replace, loadDetails])

  return (
    <View
      saveInfractionCode={saveInfractionCode}
      saveQuiz={saveQuiz}
      deleteQuiz={deleteQuiz}
      infractionCode={infractionCode}
      goBack={goBack}
      replace={replace}
    />
  )
}

export default connector(DefenceQuizzes) as React.FC<Props>
