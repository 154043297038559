import { FormikErrors } from 'formik'
import { ValueType, getSelectValue } from '@components/Select'
import { emailValidate, requiredValidate, validate } from '@services/validation'
import {
  FormAuthorRoleType,
  StateLabel,
  StrBoolean,
  StrToBoolean,
} from '@typings/enums'
import {
  FormData as UserRoleFormData,
  validateForm as validateUserRole,
} from '@common/UserRoleForm'
import city5 from '@constants/city5'

interface FormData extends UserRoleFormData {
  your_first_name: string
  your_last_name: string
  your_phone: string
  your_email: string
  your_address: string
  your_city: string
  your_state: ValueType<State> | undefined
  your_zip_code: string | number
  are_you_respondent: StrBoolean
}

export const formInitial = (
  user: WebUser | null,
  violation: ViolationDetails | null
): FormData => ({
  your_first_name: user?.firstName || '',
  your_last_name: user?.lastName + ' C/O City5 Consulting',
  your_phone: user?.phone || city5.phone,
  your_email: user?.email || city5.email || '',
  your_address: city5.address + ' ' + city5.apartmentNumber,
  your_city: city5.city,
  your_state: { value: city5.state, label: StateLabel[city5.state] },
  your_zip_code: city5.zipCode,
  are_you_respondent: StrBoolean.false,
  describes_who_you_are: FormAuthorRoleType.representative,
  name_of_person_asked_to_request: `${violation?.user?.firstName} ${violation?.user?.lastName}`,
  person_relationship_to_respondent: violation?.userRelation || '',
  are_authorized_to_represent_respondent: StrBoolean.true,
})

export const validateForm = ({
  your_first_name,
  your_last_name,
  your_phone,
  your_email,
  your_address,
  your_city,
  your_state,
  your_zip_code,
  are_you_respondent,
  ...values
}: FormData): FormikErrors<FormData> => {
  return {
    your_first_name: validate(your_first_name, requiredValidate),
    your_last_name: validate(your_last_name, requiredValidate),
    your_phone: validate(your_phone, requiredValidate),
    your_email: validate(your_email, requiredValidate, emailValidate),
    your_address: validate(your_address, requiredValidate),
    your_city: validate(your_city, requiredValidate),
    your_state: validate(getSelectValue(your_state), requiredValidate),
    your_zip_code: validate(your_zip_code, requiredValidate),
    are_you_respondent: validate(are_you_respondent, requiredValidate),
    ...validateUserRole(StrToBoolean[are_you_respondent])(values),
  }
}

export const formKeys: (keyof FormData)[] = [
  'your_first_name',
  'your_last_name',
  'your_phone',
  'your_email',
  'your_address',
  'your_city',
  'your_state',
  'your_zip_code',
  'are_you_respondent',
  'describes_who_you_are',
  'name_of_person_asked_to_request',
  'person_relationship_to_respondent',
  'are_authorized_to_represent_respondent',
]

export default FormData
