import React, { useMemo } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import clsx from 'clsx'

import Input from '@components/Input'
import BigCheckbox from '@components/BigCheckbox'

import authorRoleRadios from './authorRoleRadios'
import UserRoleFormData from './form'

import { StrBoolean } from '@typings/enums'

interface Props<R extends string = string> {
  describes_who_you_are: R | undefined
  name_of_person_asked_to_request?: string
  person_relationship_to_respondent?: string
  are_authorized_to_represent_respondent?: StrBoolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void

  errors?: FormikErrors<UserRoleFormData>
  touched?: FormikTouched<UserRoleFormData>

  hide?: boolean

  radioLabelClassName?: string
  inputWrapperClassName?: string
  withMarginClassName?: string
  inputsClassName?: string
  bigRadiosClassName?: string
  bigRadioClassName?: string
}

const UserRoleForm = <R extends string = string>({
  describes_who_you_are,
  name_of_person_asked_to_request,
  person_relationship_to_respondent,
  are_authorized_to_represent_respondent,
  onChange,

  errors,
  touched,

  hide = false,

  radioLabelClassName,
  inputWrapperClassName,
  withMarginClassName,
  inputsClassName,
  bigRadiosClassName,
  bigRadioClassName,
}: Props<R>) => {
  const authorRoleRadiosNodes = useMemo(
    () =>
      authorRoleRadios.map(({ value, label }) => (
        <Input
          key={value}
          type="radio"
          name="describes_who_you_are"
          value={value}
          checked={describes_who_you_are === value}
          onChange={onChange}
          label={label}
          labelClassName={radioLabelClassName}
          error={
            touched?.describes_who_you_are && errors?.describes_who_you_are
          }
        />
      )),
    [onChange, describes_who_you_are, touched, errors]
  )

  if (hide) {
    return null
  }

  return (
    <React.Fragment>
      <div className={clsx(inputWrapperClassName, withMarginClassName)}>
        <label className="input-label">
          {'Check the box that best describes who you are'}
          <span className="asterisk" />
        </label>
        {authorRoleRadiosNodes}
      </div>

      <Input
        type="text"
        name="name_of_person_asked_to_request"
        value={name_of_person_asked_to_request}
        onChange={onChange}
        label="What is the name of the person who asked you to make this request?"
        labelAsterisk={true}
        error={
          touched?.name_of_person_asked_to_request &&
          errors?.name_of_person_asked_to_request
        }
      />
      <Input
        type="text"
        name="person_relationship_to_respondent"
        value={person_relationship_to_respondent}
        onChange={onChange}
        label="What is that person's relationship to the named Respondent?"
        labelAsterisk={true}
        error={
          touched?.person_relationship_to_respondent &&
          errors?.person_relationship_to_respondent
        }
      />

      <div className={clsx(inputWrapperClassName, withMarginClassName)}>
        <label className="input-label">
          {'Are you authorized to represent the Respondent?'}
          <span className="asterisk" />
        </label>
        <div className={clsx(inputsClassName, bigRadiosClassName)}>
          <BigCheckbox
            type="radio"
            name="are_authorized_to_represent_respondent"
            value={StrBoolean.true}
            checked={are_authorized_to_represent_respondent === StrBoolean.true}
            onChange={onChange}
            containerClassName={bigRadioClassName}
          >
            {'Yes'}
          </BigCheckbox>
          <BigCheckbox
            type="radio"
            name="are_authorized_to_represent_respondent"
            value={StrBoolean.false}
            checked={
              are_authorized_to_represent_respondent === StrBoolean.false
            }
            onChange={onChange}
            containerClassName={bigRadioClassName}
          >
            {'No'}
          </BigCheckbox>
        </div>
      </div>
    </React.Fragment>
  )
}

export * from './form'
export type FormData = UserRoleFormData

export default UserRoleForm
