import { FormikErrors } from 'formik'
import { ValueType, getSelectValue } from '@components/Select'
import {
  CaseType,
  FormAuthorRoleType,
  StrBoolean,
  StrToBoolean,
} from '@typings/enums'
import {
  FormData as UserRoleFormData,
  validateForm as validateUserRoleForm,
} from '@common/UserRoleForm'
import { requiredValidate, validate } from '@services/validation'
import caseTypeOptions from '@pages/ViolationHearingForm/Form/GeneralInfo/caseTypeOptions'

interface FormData extends UserRoleFormData {
  case_type: ValueType<CaseType> | undefined
  summons_number: string
  first_name: string
  last_name: string
  are_you_respondent: StrBoolean
}

export const formInitial = (
  violation: ViolationDetails | null,
  user: WebUser | null
): FormData => ({
  case_type: violation
    ? caseTypeOptions.find(
        (option) => option.value === violation.infraction?.caseType
      )
    : null,
  summons_number: violation?.ticketNumber || '',
  first_name: user?.firstName || '',
  last_name: user?.lastName + ', Attn: City5 Consulting' || '',
  are_you_respondent: StrBoolean.false,
  describes_who_you_are: FormAuthorRoleType.representative,
  name_of_person_asked_to_request: `${violation?.user?.firstName} ${violation?.user?.lastName}`,
  person_relationship_to_respondent: violation?.userRelation || '',
  are_authorized_to_represent_respondent: StrBoolean.true,
})

export const validateForm = ({
  case_type,
  summons_number,
  first_name,
  last_name,
  are_you_respondent,
  ...values
}: FormData): FormikErrors<FormData> => ({
  case_type: validate(getSelectValue(case_type), requiredValidate),
  summons_number: validate(summons_number, requiredValidate),
  first_name: validate(first_name, requiredValidate),
  last_name: validate(last_name, requiredValidate),
  are_you_respondent: validate(are_you_respondent, requiredValidate),
  ...validateUserRoleForm(StrToBoolean[are_you_respondent])(values),
})

export const formKeys: (keyof FormData)[] = [
  'case_type',
  'summons_number',
  'first_name',
  'last_name',
  'are_you_respondent',
  'describes_who_you_are',
  'name_of_person_asked_to_request',
  'person_relationship_to_respondent',
  'are_authorized_to_represent_respondent',
]

export default FormData
