import { Action, combineReducers } from 'redux'

import ActionType from './types'

import user from '@redux/user'
import violations from '@redux/violations'
import payments from '@redux/payments'
import buildings from '@redux/buildings'
import infractionCodes from '@redux/infractionCodes'
import users from '@redux/users'

import { isPrimitive } from '@services/utils'

const rootReducer = combineReducers({
  user,
  violations,
  payments,
  users,
  buildings,
  infractionCodes,
})

const reducer = (state: ReduxState | undefined, action: AnyAction) => {
  switch (action.type) {
    case ActionType.RESET: {
      state = undefined
      break
    }
    case ActionType.UPDATE: {
      if (action.payload.path.length > 0) {
        let target: Record<string, unknown> = state as Record<string, unknown>
        for (const key of action.payload.path.slice(0, -1)) {
          if (!target[key] || isPrimitive(target[key])) {
            target[key] = {}
          } else {
            target = target[key] as Record<string, unknown>
          }
        }
        target[action.payload.path[action.payload.path.length - 1]] =
          action.payload.value
      }
    }
  }
  return rootReducer(state, action as Action)
}

export { rootReducer }

export default reducer
