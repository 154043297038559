import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import paths from '@routes/paths'
import pages from '@routes/pages'

const Routes: React.FC = () => (
  <Router forceRefresh={true}>
    <Switch>
      <Route exact={true} path={paths.home} component={pages.Home} />

      <Route exact={true} path={paths.login} component={pages.Login} />
      <Route
        exact={true}
        path={paths.forgotPassword}
        component={pages.ForgotPassword}
      />
      <Route
        exact={true}
        path={paths.resetPassword}
        component={pages.ResetPassword}
      />

      <Route exact={true} path={paths.defences} component={pages.Defences} />
      <Route
        exact={true}
        path={paths.defenceQuizzes}
        component={pages.DefencesQuizzes}
      />

      <Route
        exact={true}
        path={paths.hiredViolations}
        component={pages.HiredViolations}
      />
      <Route
        exact={true}
        path={paths.closedViolations}
        component={pages.ClosedViolations}
      />
      <Route
        exact={true}
        path={paths.attendedViolations}
        component={pages.AttendedViolations}
      />
      <Route
        exact={true}
        path={paths.unhiredViolations}
        component={pages.UnhiredViolations}
      />

      <Route
        exact={true}
        path={paths.violationDetails}
        component={pages.ViolationDetails}
      />

      <Route
        exact={true}
        path={paths.violationRequestReopen}
        component={pages.ViolationRequestReopen}
      />
      <Route
        exact={true}
        path={paths.violationHearingForm}
        component={pages.ViolationHearingForm}
      />

      <Route exact={true} path={paths.payments} component={pages.Payments} />

      <Route exact={true} path={paths.users} component={pages.Users} />
      <Route
        exact={true}
        path={paths.userDetails}
        component={pages.UserDetails}
      />

      <Route exact={true} path={paths.anyPath} component={pages.NotFound} />
    </Switch>
  </Router>
)

export default Routes
