import React, { useMemo } from 'react'
import ReactSelect, { ValueType as ReactSelectValueType } from 'react-select'
import clsx from 'clsx'

import styles from './styles.module.scss'
import smallStyles from './small.module.scss'

export type OptionType<
  T extends string | number = string,
  L extends React.ReactNode = React.ReactNode
> = {
  value: T
  label: L
  options?: unknown
}

export type ValueType<
  T extends string | number = string
> = ReactSelectValueType<OptionType<T>>

type ReactSelectProps<
  T extends string | number = string,
  L extends React.ReactNode = React.ReactNode
> = ReactSelect<OptionType<T, L>>['props']

interface Props<T extends string | number, L extends React.ReactNode>
  extends ReactSelectProps<T, L> {
  small?: boolean
  containerClassName?: string

  label?: React.ReactNode
  labelClassName?: string
  labelAsterisk?: boolean

  error?: React.ReactNode
  errorClassName?: string
  disableError?: boolean
}

const Select = <T extends string | number, L extends React.ReactNode>({
  small,
  className,
  containerClassName,
  isSearchable,

  label,
  labelClassName,
  labelAsterisk,

  error,
  errorClassName,
  disableError,

  ...props
}: Props<T, L>) => {
  const errorNode = useMemo(() => {
    if (disableError || small) {
      return null
    }
    if (error === null) {
      error = undefined
    }

    const errorType = typeof error
    switch (errorType) {
      case 'string':
      case 'number': {
        return (
          <p
            className={clsx('error-text', styles['error-text'], errorClassName)}
          >
            {error}
          </p>
        )
      }
      case 'undefined':
      case 'boolean': {
        return <div className="error-placeholder" />
      }
      default: {
        return error
      }
    }
  }, [error, errorClassName, disableError])

  const labelNode = useMemo(() => {
    if (small) {
      return null
    }
    return (
      label && (
        <label className={clsx('input-label', labelClassName)}>
          {label}
          {labelAsterisk ? <span className="asterisk" /> : null}
        </label>
      )
    )
  }, [label, labelClassName, labelAsterisk])

  return (
    <div className={clsx(styles['select-container'], containerClassName)}>
      {labelNode}
      <ReactSelect
        {...props}
        classNamePrefix="react-select"
        className={clsx(
          (small ? smallStyles : styles)['react-select'],
          error && styles['error'],
          className
        )}
        isSearchable={
          isSearchable === undefined && small ? false : isSearchable
        }
      />
      {errorNode}
    </div>
  )
}

export * from './services'

export default Select
