import React from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import clsx from 'clsx'

import Textarea from '@components/Textarea'

import FormData from './form'

import sharedStyles from '../styles.module.scss'

interface Props {
  form: FormData
  touched: FormikTouched<FormData>
  errors: FormikErrors<FormData>
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

const Defense: React.FC<Props> = ({ form, touched, errors, onChange }) => {
  return (
    <React.Fragment>
      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            {'Why the summons should be dismissed'}
          </h2>
          <p className={sharedStyles['section-description']}>
            {'If there is more than one charge listed on the summons, ' +
              'write a defense to each and every charge.'}
          </p>
        </div>
        <div className={clsx(sharedStyles['inputs'])}>
          <Textarea
            name="why_should_be_dismissed"
            value={form.why_should_be_dismissed}
            onChange={onChange}
            numberOfLines={15}
            error={
              touched.why_should_be_dismissed && errors.why_should_be_dismissed
            }
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Defense
