import React from 'react'
import { useDrop } from 'react-dnd'

interface Props {
  name: string
  setLastDragHoveredElement: Function
}

const Dropzone: React.FC<Props> = ({
  children,
  name,
  setLastDragHoveredElement,
}) => {
  const [, drop] = useDrop({
    drop: () => ({ name }),
    accept: 'questions',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hover(item: any, monitor) {
      const clientOffset = monitor.getClientOffset()
      if (clientOffset) {
        const el = document.elementFromPoint(clientOffset.x, clientOffset.y)
        if (el) {
          console.log(el.textContent)
          // @ts-ignore
          window.lastDragHovered = el
          setLastDragHoveredElement(el)
        }
      }
      // Get pixels to the top
    },
  })

  return <div ref={drop}>{children}</div>
}

export default Dropzone
