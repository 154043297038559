import React, { useState } from 'react'
import { useDrag } from 'react-dnd'
import styles from '../styles.module.scss'
import { toast } from 'react-toastify'

const placeHolder =
  'Please double click here and enter text of defence.' +
  ' When you need to place answer for quiz question in text add {Q1}' +
  ' with appropriate number after Q and it would be auto replaced later'

interface Props {
  defence: string | null | undefined
  saveDefence: Function
  handleDefenceChange: Function
  questions: Question[] | undefined
}

const EditableDefence: React.FC<Props> = ({
  defence,
  saveDefence,
  handleDefenceChange,
  questions,
}) => {
  const [editing, setEditing] = useState(false)

  const onDefenceEdit = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = event
    handleDefenceChange(value, editing)
  }

  const handleEditing = (value: boolean) => {
    setEditing(value)
    if (!value) {
      toast('Please do nor forget to press "Save Defence"')
    }
  }

  const onSave = () => {
    setEditing(false)
    saveDefence()
  }

  const onClear = () => {
    setEditing(false)
    handleDefenceChange(null)
  }

  return (
    <div
      onDoubleClick={() => setEditing(true)}
      onBlur={() => handleEditing(false)}
      className={styles['defence-section']}
    >
      {editing && (
        <textarea placeholder={placeHolder} onChange={onDefenceEdit} rows={10}>
          {defence}
        </textarea>
      )}
      {!editing && (
        <p>
          {(defence || placeHolder).split(' ').map((word, index) => (
            <DraggableDefence
              questions={defence ? questions : []}
              index={index}
              defence={defence}
              handleDefenceChange={handleDefenceChange}
              key={index}
              word={word}
            />
          ))}
        </p>
      )}
      <div className={styles['control-panel']}>
        <button
          onClick={onClear}
          onMouseDown={(e) => e.preventDefault()}
          className={styles['clear-button']}
        >
          Clear
        </button>
        <button
          onClick={onSave}
          onMouseDown={(e) => e.preventDefault()}
          className={styles['save-button']}
        >
          Save Defence
        </button>
        <button
          onClick={() => handleEditing(false)}
          className={styles['cancel-button']}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

interface DraggableDefenceProps {
  word: string
  index: number
  defence: string | null | undefined
  handleDefenceChange: Function
  questions: Question[] | undefined
}
const DraggableDefence: React.FC<DraggableDefenceProps> = ({
  word,
  index,
  defence,
  handleDefenceChange,
  questions,
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { word, type: 'questions' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item) => {
      if (
        defence &&
        // @ts-ignore
        !window.lastDragHovered.parentNode.innerHTML.includes('styles_defence')
      ) {
        // @ts-ignore
        const newDefence = defence.replace(item.word, '')
        // @ts-ignore
        handleDefenceChange(newDefence)
      }
    },
  })
  let shouldDrug = false
  let questionText = null
  if (word.includes('{Q') && questions) {
    shouldDrug = true
    const question = questions?.find(
      (question) =>
        question.orderNumber === Number(word.replaceAll(/[{}Q]/g, ''))
    )
    questionText = question?.question
  }
  const opacity = isDragging ? 0.4 : 1
  return (
    <>
      <span
        className={shouldDrug ? styles['drag-able-text'] : ''}
        ref={shouldDrug ? drag : null}
        style={{ opacity }}
        key={index}
        data-index={index}
      >
        {questionText ? `Q:${questionText}` : word}
      </span>
      <span style={{ opacity }} key={index + word}>
        {' '}
      </span>
    </>
  )
}

export default EditableDefence
