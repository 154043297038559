import api from '@services/api'

import action from '@redux/action'
import ActionType from './types'

import { apiRoutes } from '@config'

const loading = (isLoading = true): AppThunk => (dispatch) =>
  dispatch(action(ActionType.LOADING, isLoading))

const error = (error: ErrorType): AppThunk => (dispatch) =>
  dispatch(action(ActionType.ERROR, error))

const reset = (): AppThunk => (dispatch) => dispatch(action(ActionType.RESET))

const login = (
  email: string,
  password: string,
  remember: boolean = false,
  onSuccess?: (user: WebUser) => void,
  onError?: (error: ErrorType) => void
): AppThunk<WebUser> => async (dispatch) => {
  dispatch(action(ActionType.LOGIN_BEGIN))

  //todo: add remember me functionality
  console.log('remember =>', remember)

  try {
    const response = await api.post<ApiSuccessResponse<WebUser>>(
      apiRoutes.login,
      {
        email,
        password,
      }
    )

    onSuccess?.(response.data.data)
    dispatch(action(ActionType.LOGIN_SUCCESS, response.data.data))
    return response.data.data
  } catch (error) {
    dispatch(action(ActionType.LOGIN_ERROR, error))
    onError?.(error)
  }
}

const logout = (
  onSuccess?: () => void,
  onError?: (error: ErrorType) => void
): AppThunk => async (dispatch) => {
  dispatch(action(ActionType.LOGOUT_BEGIN))

  try {
    localStorage.clear()
    //todo uncomment after implementation on backend
    // await api.delete<ApiSuccessResponse<null>>(apiRoutes.logout)

    onSuccess?.()
    dispatch(action(ActionType.LOGOUT_SUCCESS))
  } catch (error) {
    dispatch(action(ActionType.LOGOUT_ERROR, error))
    onError?.(error)
  }
}

const forgotPassword = (
  email: string,
  onSuccess?: (email: string) => void,
  onError?: (error: ErrorType) => void
): AppThunk<string> => async (dispatch) => {
  dispatch(action(ActionType.FORGOT_PASSWORD_BEGIN))

  try {
    await api.post<ApiSuccessResponse>(apiRoutes.forgotPassword, {
      email,
    })

    dispatch(action(ActionType.FORGOT_PASSWORD_SUCCESS, email))
    onSuccess?.(email)
    return email
  } catch (error) {
    dispatch(action(ActionType.FORGOT_PASSWORD_ERROR, error))
    onError?.(error)
  }
}

const resetPassword = (
  password: string,
  url: string,
  onSuccess?: (email: WebUser) => void,
  onError?: (error: ErrorType) => void
): AppThunk<WebUser> => async (dispatch) => {
  dispatch(action(ActionType.RESET_PASSWORD_BEGIN))

  try {
    const response = await api.post<ApiSuccessResponse<WebUser>>(
      apiRoutes.resetPassword,
      { password },
      {
        baseURL: url,
      }
    )

    dispatch(action(ActionType.RESET_PASSWORD_SUCCESS, response.data.data))
    onSuccess?.(response.data.data)
    return response.data.data
  } catch (error) {
    dispatch(action(ActionType.RESET_PASSWORD_ERROR, error))
    onError?.(error)
  }
}

const userActions = {
  loading,
  error,
  reset,
  login,
  logout,
  forgotPassword,
  resetPassword,
}

export default userActions
