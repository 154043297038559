import React, { useCallback, useMemo } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import clsx from 'clsx'

import CalendarInput from '@components/CalendarInput'
import Textarea from '@components/Textarea'
import Input from '@components/Input'
import InfoTooltip from '@common/InfoTooltip'

import FormData from './form'
import radios, { ReasonForGranted } from './radios'
import checkboxes from './checkboxes'
import { nycDateFormat } from '@constants/date'

import sharedStyles from '../styles.module.scss'
import styles from './styles.module.scss'

interface Props {
  form: FormData
  touched: FormikTouched<FormData>
  errors: FormikErrors<FormData>
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  setFieldValue: <F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F]
  ) => void
}

const ReasonableExcuse: React.FC<Props> = ({
  form,
  touched,
  errors,
  setFieldValue,
  onChange,
}) => {
  const onDateChange = useCallback(
    (_dates: Date[], _dateStr: string, _self: unknown, _data: unknown) => {
      setFieldValue('date_respondent_learn_about_summons', _dateStr)
    },
    [setFieldValue]
  )

  const radiosNodes = useMemo(
    () =>
      radios.map(({ value, label }) => (
        <Input
          key={value}
          name="reason_to_grant_new_hearing"
          type="radio"
          value={value}
          checked={form.reason_to_grant_new_hearing === value}
          onChange={onChange}
          label={label}
          labelClassName={sharedStyles['radio-label']}
        />
      )),
    [onChange, form.reason_to_grant_new_hearing]
  )

  const checkboxesNodes = useMemo(
    () =>
      checkboxes.map(({ label, tooltip }, index) => (
        <Input
          key={index}
          name={`reasonable_excuse[${index}]`}
          type="checkbox"
          checked={form.reasonable_excuse[index] as boolean}
          onChange={onChange}
          label={
            tooltip ? (
              <React.Fragment>
                {label}
                <InfoTooltip>{tooltip}</InfoTooltip>
              </React.Fragment>
            ) : (
              label
            )
          }
          labelClassName={sharedStyles['checkbox-label']}
        />
      )),
    [onChange, form.reasonable_excuse]
  )

  const moreThan60DaysNode = useMemo(
    () => (
      <React.Fragment>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            {'Reasonable Excuse'}
            <span className="asterisk" />
          </h2>
        </div>
        <div
          className={clsx(
            sharedStyles['inputs'],
            sharedStyles['checkboxes'],
            styles['checkboxes']
          )}
        >
          {checkboxesNodes}
          {touched.reasonable_excuse && errors.reasonable_excuse ? (
            <p className="error-text">{errors.reasonable_excuse}</p>
          ) : (
            <p className="error-placeholder" />
          )}
        </div>
        <div className={clsx(sharedStyles['inputs'])}>
          {form.reasonable_excuse[6] ? (
            <Input
              type="text"
              name="respondents_connection_to_property_and_when_ended"
              value={form.respondents_connection_to_property_and_when_ended}
              onChange={onChange}
              label="Respondent's connection to the property and when that connection ended"
              labelAsterisk={true}
              required={true}
              error={
                touched.respondents_connection_to_property_and_when_ended &&
                errors.respondents_connection_to_property_and_when_ended
              }
            />
          ) : null}
          {form.reasonable_excuse[8] ? (
            <Textarea
              name="explain"
              value={form.explain}
              onChange={onChange}
              numberOfLines={3}
              label="Explain"
              labelAsterisk={true}
              required={true}
              error={touched.explain && errors.explain}
            />
          ) : null}
        </div>
      </React.Fragment>
    ),
    [
      checkboxesNodes,
      touched.reasonable_excuse,
      errors.reasonable_excuse,
      form.respondents_connection_to_property_and_when_ended,
      touched.respondents_connection_to_property_and_when_ended,
      errors.respondents_connection_to_property_and_when_ended,
      form.explain,
      touched.explain,
      errors.explain,
    ]
  )

  const moreThan1YearNode = useMemo(
    () => (
      <div className={sharedStyles['inputs']}>
        <Textarea
          name="explain_what_circumstances"
          value={form.explain_what_circumstances}
          onChange={onChange}
          numberOfLines={3}
          label={
            <React.Fragment>
              {'Explain what those circumstances are:'}
              <span className="asterisk" />
              <InfoTooltip>
                {'For summonses charging violations of any laws or ' +
                  'regulations that the Taxi and Limousine Commission ' +
                  '(TLC) has the duty or authority to enforce, this request ' +
                  'must be filed within 2 years from the date of the default ' +
                  'decision. The request will only be granted in exceptional circumstances.'}
              </InfoTooltip>
            </React.Fragment>
          }
          required={true}
          error={
            touched.explain_what_circumstances &&
            errors.explain_what_circumstances
          }
        />
      </div>
    ),
    [
      onChange,
      form.explain_what_circumstances,
      touched.explain_what_circumstances,
      errors.explain_what_circumstances,
    ]
  )

  const reason = useMemo(() => {
    switch (form.reason_to_grant_new_hearing) {
      case ReasonForGranted.morethan60days: {
        return moreThan60DaysNode
      }
      case ReasonForGranted.morethan1year: {
        return moreThan1YearNode
      }
      default: {
        return null
      }
    }
  }, [form.reason_to_grant_new_hearing, moreThan60DaysNode, moreThan1YearNode])

  return (
    <React.Fragment>
      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <label
            className={clsx(
              sharedStyles['section-title'],
              sharedStyles['section-title-small']
            )}
          >
            {'On what date did Respondent first learn about this summons?'}
            <span className="asterisk" />
          </label>
        </div>
        <div
          className={clsx(
            sharedStyles['inputs'],
            sharedStyles['two-columns'],
            styles['date-inputs']
          )}
        >
          <CalendarInput
            onChange={onDateChange}
            name="firstLearnDate"
            value={form.date_respondent_learn_about_summons}
            label="Select date"
            options={{ dateFormat: nycDateFormat }}
            error={
              touched.date_respondent_learn_about_summons &&
              errors.date_respondent_learn_about_summons
            }
          />
        </div>
        <div className={clsx(sharedStyles['inputs'])}>
          <Textarea
            label="How did the Respondent learn about the summons?"
            labelClassName={sharedStyles['bold-label']}
            labelAsterisk={true}
            numberOfLines={3}
            name="how_respondent_learned_about_summons"
            value={form.how_respondent_learned_about_summons}
            onChange={onChange}
            error={
              touched.how_respondent_learned_about_summons &&
              errors.how_respondent_learned_about_summons
            }
          />
        </div>
      </div>
      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            {'Reason for which a new hearing should be granted'}
            <span className="asterisk" />
          </h2>
        </div>
        <div
          className={clsx(
            sharedStyles['inputs'],
            sharedStyles['radios'],
            styles['radios']
          )}
        >
          {radiosNodes}
        </div>
      </div>
      <div className={sharedStyles['section']}>{reason}</div>
    </React.Fragment>
  )
}

export default ReasonableExcuse
