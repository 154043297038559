import { defaultFontSize } from '@config'

export const toRem = (num: number) => `${num / defaultFontSize}rem`

const createId = (() => {
  let val = 0
  return () => `id-${val++}`
})()

export const createUniqueId = () => {
  let id = createId()
  while (document.getElementById(id)) {
    id = createId()
  }
  return id
}

interface OpenNewHTMLConfigs {
  url?: string
  target?: string
}

const defaultOpenNewHTMLConfigs: OpenNewHTMLConfigs = {
  url: '',
  target: '_blank',
}

export const openNewHTML = (html: string, configs: OpenNewHTMLConfigs = {}) => {
  configs = { ...defaultOpenNewHTMLConfigs, ...configs }
  html = html.replace(/<html>|<\/html>|<body>|<\/body>/, '')
  const newWindow = window.open(configs.url, configs.target)
  newWindow?.document.write(html)
  return newWindow
}

export default {
  toRem,
  createUniqueId,
}
