import React, { Dispatch, SetStateAction, useMemo } from 'react'
import clsx from 'clsx'

import Link from '@components/Link'

import {
  CaseStatusLabel,
  CaseStatusType,
  ViolationDefenseClassName,
  ViolationDefenseType,
} from '@typings/enums'
import links from '@routes/links'
import { openNewHTML } from '@services/dom'

import styles from '../styles.module.scss'

interface Props {
  defenseType: ViolationDefenseType | null
  defense: string | null | undefined
  caseStatus: CaseStatusType
  violationID: ApiID
  setModalState: Dispatch<SetStateAction<boolean>>
}

const TopSection: React.FC<Props> = ({
  defenseType,
  defense,
  caseStatus,
  violationID,
  setModalState,
}) => {
  const content = useMemo(() => {
    const notPendingHearing = caseStatus !== CaseStatusType.pendingHearing
    switch (defenseType) {
      case ViolationDefenseType.ecbHearing: {
        return (
          <React.Fragment>
            <div className={styles['text']}>
              <p className={styles['title']}>{'ECB online hearing'}</p>
              <p className={styles['subtitle']}>
                {'Need to Send ECB Online Hearing Form'}
              </p>
            </div>
            <div className={styles['progress-container']}>
              <p className={styles['progress-text']}>1/2</p>
              <progress value={1} max={2} className={styles['progress']} />
            </div>
            <Link
              to={links.violationHearingForm(violationID)}
              className={'primary-button'}
              title={
                notPendingHearing ? CaseStatusLabel[caseStatus] : undefined
              }
              disabled={notPendingHearing}
            >
              {'Attend Hearing'}
            </Link>
          </React.Fragment>
        )
      }
      case ViolationDefenseType.defended: {
        const showDefence = () => {
          defense && openNewHTML(defense)
        }
        return (
          <button
            className={clsx('primary-button')}
            // onClick={showDefence}
            onClick={() => setModalState(true)}
            onDoubleClick={showDefence}
          >
            {'View Defence'}
          </button>
        )
      }
      default: {
        return null
      }
    }
  }, [defenseType, caseStatus, defense])

  return (
    <section
      className={clsx(
        styles['section'],
        styles['top-section'],
        defenseType && styles[ViolationDefenseClassName[defenseType]]
      )}
    >
      {content}
    </section>
  )
}

export default TopSection
