import React, { useRef } from 'react'
import styles from '../styles.module.scss'
import Modal from '@components/Modal'
import CloseIcon from '@assets/close-circle.svg'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'
import QuestionItem from '@pages/DefenceQuizzes/QuizModal/QuestionItem'
import AddButton from '@assets/add-circle.svg'
import { question } from '@pages/DefenceQuizzes/view'

interface Props {
  currentQuiz: Quiz
  setCurrentQuiz: Function
  saveQuiz: Function
  onRequestClose: () => void
  open: boolean
}

const QuizModal: React.FC<Props> = ({
  onRequestClose,
  open,
  currentQuiz,
  setCurrentQuiz,
  saveQuiz,
}) => {
  const nameInput = useRef<HTMLInputElement>(null)
  const focusInput = (inputRef: React.RefObject<{ focus: () => void }>) => {
    const current = inputRef.current
    if (current) {
      current.focus()
    }
  }
  const blurInput = (inputRef: React.RefObject<{ blur: () => void }>) => {
    const current = inputRef.current
    if (current) {
      current.blur()
    }
  }

  const setName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event
    setCurrentQuiz({ ...currentQuiz, name: value || '' })
  }

  const setQuestions = (index: number, question: Question) => {
    const questions = [...currentQuiz.questions]
    questions[index] = question
    setCurrentQuiz({ ...currentQuiz, questions })
  }

  const addQuestion = () => {
    const questions = [...currentQuiz.questions]
    questions.push({ ...question, orderNumber: ++currentQuiz.questions.length })
    setCurrentQuiz({ ...currentQuiz, questions })
  }

  return (
    <Modal open={open} onRequestClose={onRequestClose} coverScreen={false}>
      <div onClick={onRequestClose} className={styles['quiz-modal']} />
      <div className={styles['quiz-modal-container']}>
        <form
          className={styles['quiz-modal-content']}
          onSubmit={(event) => {
            event.preventDefault()
            saveQuiz(currentQuiz)
            onRequestClose()
          }}
        >
          <div className={styles['quiz-header']}>
            <ReactSVG
              onClick={onRequestClose}
              src={CloseIcon}
              className={clsx('svg', styles['close-button'])}
            />
            <div className={styles['content']}>
              <label
                onMouseEnter={() => focusInput(nameInput)}
                onMouseLeave={() => blurInput(nameInput)}
                htmlFor="quiz-name"
              >
                Quiz name
              </label>
              <input
                onMouseEnter={() => focusInput(nameInput)}
                ref={nameInput}
                required={true}
                id="quiz-name"
                value={currentQuiz.name}
                onChange={setName}
              />
            </div>
          </div>
          <div className={styles['quiz-body']}>
            {currentQuiz.questions.map((question, index) => (
              <QuestionItem
                setQuestion={(q: Question) => setQuestions(index, q)}
                key={question.orderNumber}
                question={question}
              />
            ))}
            {currentQuiz.questions.length < 5 && (
              <button onClick={addQuestion} className={styles['add-button']}>
                <ReactSVG
                  src={AddButton}
                  className={clsx('svg', styles['logo'])}
                />
              </button>
            )}
            <button type="submit" className={styles['save-button']}>
              Save Quiz
            </button>
            <button
              type="button"
              onClick={onRequestClose}
              className={styles['cancel-button']}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default QuizModal
