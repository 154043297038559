import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'
import { ValueType } from 'react-select'

import Select, { OptionType, getSelectValue } from '@components/Select'
import BuildingGroup from './BuildingGroup'
import ViolationHeadRow from './ViolationHeadRow'
import ViolationRow from './ViolationRow'
import Filters from './Filters'

import violationsActions from '@redux/violations/actions'
import {
  SortDirection,
  ViolationGroupType,
  ViolationSortType,
  ViolationType,
  ViolationTypeLabel,
} from '@typings/enums'
import { emptyFilter, emptySort } from '@services/violation'
import useThreshold from '@hooks/useThreshold'
import { defaultPerPage } from '@config'

import BuildingIcon from '@assets/building.svg'
import CalendarIcon from '@assets/calendar.svg'

import styles from '../styles.module.scss'

const mapStateToProps = (state: ReduxState) => ({
  violations: state.violations.violations,
  isLoading: state.violations.isLoading,
  page: state.violations.params.page,
  totalPages: state.violations.params.totalPages,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

interface NativeProps {
  userId: string
}

type Props = NativeProps & ConnectedProps<typeof connector>

const types: ViolationType[] = [
  ViolationType.hired,
  ViolationType.closed,
  ViolationType.attended,
  ViolationType.unhired,
]

const groupByOptions: OptionType<ViolationGroupType>[] = [
  {
    value: ViolationGroupType.buildingAddress,
    label: (
      <>
        <ReactSVG
          src={BuildingIcon}
          className={clsx(
            'svg',
            styles['select-icon'],
            styles['building-icon']
          )}
        />
        <p className={styles['option-text']}>{'Buildings'}</p>
      </>
    ),
  },
  {
    value: ViolationGroupType.hearingDate,
    label: (
      <>
        <ReactSVG
          src={CalendarIcon}
          className={clsx(
            'svg',
            styles['select-icon'],
            styles['calendar-icon']
          )}
        />
        <p className={styles['option-text']}>{'Date'}</p>
      </>
    ),
  },
]

const TableSection: React.FC<Props> = ({
  userId,
  violations = [],
  isLoading,
  page,
  totalPages,
  violationsActions: { load: loadViolations, sort: sortViolations },
}) => {
  const [selectedType, setSelectedType] = useState(ViolationType.hired)

  const [groupBy, setGroupBy] = useState(ViolationGroupType.buildingAddress)

  const [filtersOpen, setFiltersOpen] = useState(false)

  const openFilters = () => setFiltersOpen(true)
  const closeFilters = () => setFiltersOpen(false)

  useEffect(() => {
    const params: ViolationsApiLoadOptions = {
      type: selectedType,
      user_id: userId,
      page: 1,
      per_page: defaultPerPage,
      ...emptyFilter,
      ...emptySort,
    }
    switch (groupBy) {
      case ViolationGroupType.buildingAddress: {
        params.group_by = groupBy
        break
      }
      case ViolationGroupType.hearingDate: {
        params.group_by = undefined
        params.order_by = ViolationSortType.hearingDate
        params.order_by_direction = SortDirection.desc
        break
      }
    }
    loadViolations(params, true)
  }, [selectedType, userId, loadViolations, groupBy])

  const groupByValue = useMemo(
    () => groupByOptions.find((option) => option.value === groupBy),
    [groupBy]
  )

  const onGroupByValueChange = useCallback(
    (option: ValueType<OptionType<ViolationGroupType>>) => {
      const value = getSelectValue(option)
      if (value && !Array.isArray(value)) {
        setGroupBy(value)
      }
    },
    [setGroupBy]
  )

  const typeLinks = useMemo(
    () =>
      types.map((type) => (
        <li key={type} className={styles['link-item']}>
          <a
            className={clsx(
              styles['link'],
              type === selectedType && styles['link-active']
            )}
            onClick={() => setSelectedType(type)}
          >
            {ViolationTypeLabel[type]}
          </a>
        </li>
      )),
    [selectedType, setSelectedType]
  )

  const data = useMemo(() => {
    switch (groupBy) {
      case ViolationGroupType.buildingAddress: {
        return Array.isArray(violations) ? null : (
          <ul className={styles['groups']}>
            {Object.keys(violations)
              .sort((a, b) => a.localeCompare(b))
              .map((key) => (
                <BuildingGroup
                  key={`${key}-${selectedType}`}
                  title={key}
                  type={selectedType}
                  violations={violations[key]}
                />
              ))}
          </ul>
        )
      }
      case ViolationGroupType.hearingDate: {
        const dateCompare = (a: string, b: string) =>
          new Date(a).getTime() - new Date(b).getTime()

        const viols = Array.isArray(violations)
          ? violations
          : Object.keys(violations)
              .sort(dateCompare)
              .reduce(
                (acc, key) => [
                  ...acc,
                  ...(violations as { [key: string]: Violation[] })[key],
                ],
                [] as Violation[]
              )

        const tableBody = viols.map((violation, index) => (
          <ViolationRow
            key={`${violation.id} - ${index}`}
            groupBy={groupBy}
            violation={violation}
          />
        ))

        return (
          <table className={styles['table']}>
            <thead className={styles['table-head']}>
              <ViolationHeadRow
                type={selectedType}
                groupBy={groupBy}
                onHearingDateClick={() => {
                  sortViolations({
                    order_by: ViolationSortType.hearingDate,
                    page: 1,
                  })
                }}
                onBalanceClick={() => {
                  sortViolations({
                    order_by: ViolationSortType.balance,
                    page: 1,
                  })
                }}
                onStatusClick={() => {
                  sortViolations({
                    order_by: ViolationSortType.status,
                    page: 1,
                  })
                }}
              />
            </thead>
            <tbody className={styles['table-body']}>{tableBody}</tbody>
          </table>
        )
      }
      default: {
        return null
      }
    }
  }, [violations])

  const onThreshold = useCallback(
    (cb: () => void) => {
      if (!isLoading && (!page || !totalPages || page < totalPages)) {
        loadViolations({ per_page: defaultPerPage }, undefined, false, cb, cb)
      } else {
        cb()
      }
    },
    [loadViolations, page, totalPages, isLoading]
  )

  const thresholdRef = useThreshold(0.7, onThreshold, [data])

  return (
    <section className={styles['table-section']}>
      <div className={styles['heading']}>
        <div className={styles['group-by-container']}>
          <p className={styles['group-by-text']}>{'Group by'}</p>
          <Select
            options={groupByOptions}
            small={true}
            className={styles['group-by-select']}
            value={groupByValue}
            onChange={onGroupByValueChange}
          />
        </div>
        <nav className={styles['navigation']}>
          <ul className={styles['links']}>{typeLinks}</ul>
          <button className="primary-button" onClick={openFilters}>
            {'Filter'}
          </button>
        </nav>
      </div>
      <div ref={thresholdRef}>{data}</div>
      <Filters open={filtersOpen} close={closeFilters} groupBy={groupBy} />
    </section>
  )
}

export default connector(TableSection) as React.FC<NativeProps>
