import { halfYearBars, minChartDots } from '@constants/income'
import { addDate, getShortMonth } from '@services/date'

import { IncomePeriodType } from '@typings/enums'
import { getAmount } from './money'

const formatLabel = (period: IncomePeriodType): ((date: Date) => string) => {
  switch (period) {
    case IncomePeriodType.daily:
    case IncomePeriodType.weekly: {
      return (date) => `${getShortMonth(date)} ${date.getUTCDate()}`
    }
    case IncomePeriodType.monthly: {
      return (date) => `${getShortMonth(date)} ${date.getUTCFullYear()}`
    }
    case IncomePeriodType.yearly: {
      return (date) => String(date.getUTCFullYear())
    }
    default: {
      return (date) => date.toUTCString()
    }
  }
}

const padDate = (
  date: Date,
  period: IncomePeriodType
): ((diff: number) => Date) => {
  switch (period) {
    case IncomePeriodType.daily: {
      return (diff) => addDate(date, diff, 'day')
    }
    case IncomePeriodType.weekly: {
      return (diff) => addDate(date, diff, 'week')
    }
    case IncomePeriodType.monthly: {
      return (diff) => addDate(date, diff, 'month')
    }
    case IncomePeriodType.yearly:
    default: {
      return (diff) => addDate(date, diff, 'year')
    }
  }
}

const parseData = (incomeStats: IncomeStats): IncomeChartData[] =>
  Object.keys(incomeStats).map((key) => ({
    value: getAmount(incomeStats[key].income),
    label: key,
    percent: incomeStats[key].percent,
  }))

export const getData = (
  incomeStats: IncomeStats,
  period: IncomePeriodType
): IncomeChartData[] => {
  const actualData = parseData(incomeStats)

  const fmtLabel = formatLabel(period)

  const sortedData = actualData
    .map(({ label, ...val }) => ({ ...val, label: new Date(label) }))
    .filter(({ label }) => !isNaN(label.getTime()))
    .sort((a, b) => a.label.getTime() - b.label.getTime())

  const minDate = sortedData[0]?.label || new Date()

  const data = sortedData.map(({ label, ...val }) => ({
    ...val,
    label: fmtLabel(label),
  }))

  const padStartLen = minChartDots - data.length
  const padStartDate = padDate(minDate, period)

  const padStartData: IncomeChartData[] =
    padStartLen > 0
      ? [...new Array(padStartLen)].map((_, index) => ({
          value: 0,
          label: fmtLabel(padStartDate(index - padStartLen)),
          percent: 0,
        }))
      : []

  return [...padStartData, ...data]
}

export const getLastHalfYearData = (incomeStats: IncomeStats): number[] => {
  const actualData = parseData(incomeStats)

  const sortedData = actualData
    .map(({ label, ...val }) => ({ ...val, label: new Date(label) }))
    .filter(({ label }) => !isNaN(label.getTime()))
    .sort((a, b) => a.label.getTime() - b.label.getTime())

  const data = sortedData.map(({ value }) => value).slice(-halfYearBars)

  const padStartLen = halfYearBars - data.length

  const padStartData: number[] =
    padStartLen > 0 ? [...new Array(padStartLen)].fill(0) : []

  return [...padStartData, ...data]
}
