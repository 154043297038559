import React, { useMemo } from 'react'

import ViolationRow from '../ViolationRow'
import ViolationHeadRow from '../ViolationHeadRow'
import PagingFooter from '@common/PagingFooter'
import { ViolationSortType, ViolationType } from '@typings/enums'
import { violationsAvailablePerPage } from '@config'

import styles from './styles.module.scss'

interface Props<Type extends Violation = Violation> {
  violations: Type[]
  sort: (type: ViolationSortType | undefined) => void
  page: number
  perPage: number
  totalPages: number
  type: ViolationType
  load: (params: PagingParams) => void
  isLoading: boolean
}

const View = <Type extends Violation = Violation>({
  violations,
  sort,
  page,
  perPage,
  totalPages,
  type,
  load,
  isLoading,
}: Props<Type>) => {
  const tableBody = useMemo(
    () =>
      violations.map((violation, index) => (
        <ViolationRow key={`${violation.id}-${index}`} violation={violation} />
      )),
    [violations]
  )

  return (
    <div className={styles['table-container']}>
      <table className={styles['table']}>
        <thead className={styles['table-head']}>
          <ViolationHeadRow
            type={type}
            onRespondentClick={() => {
              sort(ViolationSortType.respondent)
            }}
            onHearingDateClick={() => {
              sort(ViolationSortType.hearingDate)
            }}
            onBalanceClick={() => {
              sort(ViolationSortType.balance)
            }}
            onStatusClick={() => {
              sort(ViolationSortType.status)
            }}
          />
        </thead>
        <tbody className={styles['table-body']}>{tableBody}</tbody>
      </table>
      <PagingFooter
        availablePerPage={violationsAvailablePerPage}
        isLoading={isLoading}
        page={page}
        perPage={perPage}
        totalPages={totalPages}
        onChange={load}
      />
    </div>
  )
}

export default View
