import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import debounce from 'lodash.debounce'

const useThreshold = (
  threshold: number,
  onThreshold?: (cb: () => void) => void,
  deps: React.DependencyList = []
) => {
  const [isFetching, setIsFetching] = useState(false)
  const localRef = useRef<HTMLDivElement>(null)

  const callback = debounce(() => {
    if (!localRef.current || isFetching || !threshold || threshold < 0) {
      return
    }

    const target =
      localRef.current.offsetTop +
      (threshold < 1
        ? localRef.current.clientHeight * threshold
        : localRef.current.clientHeight - threshold)

    if (window.scrollY + window.innerHeight > target && onThreshold) {
      setIsFetching(true)
      onThreshold(() => setIsFetching(false))
    }
  })

  useEffect(callback, [localRef.current, ...deps])

  useLayoutEffect(() => {
    window.addEventListener('scroll', callback)
    return () => window.removeEventListener('scroll', callback)
  }, [localRef.current, callback])

  return localRef
}

export default useThreshold
