import React, { useCallback } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import clsx from 'clsx'

import Input from '@components/Input'
import Select, { ValueType } from '@components/Select'
import BigCheckbox from '@components/BigCheckbox'
import UserRoleForm from '@common/UserRoleForm'

import FormData from './form'
import caseTypeOptions from './caseTypeOptions'
import { StrBoolean, StrToBoolean } from '@typings/enums'

import sharedStyles from '../styles.module.scss'

interface Props {
  form: FormData
  touched: FormikTouched<FormData>
  errors: FormikErrors<FormData>
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setFieldValue: <F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F],
    shouldValidate?: boolean
  ) => void
}

const GeneralInfo: React.FC<Props> = ({
  form,
  touched,
  errors,
  onChange,
  setFieldValue,
}) => {
  const onCaseTypeChange = useCallback(
    (e: ValueType<CaseType>) => setFieldValue('case_type', e, false),
    [setFieldValue]
  )

  return (
    <React.Fragment>
      <div className={sharedStyles['section']}>
        <div
          className={clsx(sharedStyles['inputs'], sharedStyles['two-columns'])}
        >
          <Select
            value={form.case_type}
            options={caseTypeOptions}
            onChange={onCaseTypeChange}
            label="Case Type"
            labelAsterisk={true}
            error={touched.case_type && errors.case_type}
          />
          <Input
            type="text"
            name="summons_number"
            value={form.summons_number}
            onChange={onChange}
            label="Summons Number"
            labelAsterisk={true}
            placeholder="000 000 000"
            required={true}
            error={touched.summons_number && errors.summons_number}
          />
        </div>
      </div>
      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            {'Name of person completing this form'}
          </h2>
        </div>
        <div
          className={clsx(
            sharedStyles['sub-section'],
            sharedStyles['inputs'],
            sharedStyles['two-columns']
          )}
        >
          <Input
            type="text"
            name="first_name"
            value={form.first_name}
            onChange={onChange}
            label="Your First Name"
            labelAsterisk={true}
            placeholder="e.g. John"
            required={true}
            error={touched.first_name && errors.first_name}
          />
          <Input
            type="text"
            name="last_name"
            value={form.last_name}
            onChange={onChange}
            label="Your Last Name"
            labelAsterisk={true}
            placeholder="e.g. Millere"
            required={true}
            error={touched.last_name && errors.last_name}
          />
        </div>
        <div className={sharedStyles['sub-section']}>
          <div
            className={clsx(
              sharedStyles['input-wrapper'],
              !StrToBoolean[form.are_you_respondent] &&
                sharedStyles['with-margin']
            )}
          >
            <label className="input-label">
              {'Does your name appear on the ticket?'}
              <span className="asterisk" />
            </label>
            <div
              className={clsx(
                sharedStyles['inputs'],
                sharedStyles['big-radios']
              )}
            >
              <BigCheckbox
                type="radio"
                name="are_you_respondent"
                value={StrBoolean.true}
                checked={StrToBoolean[form.are_you_respondent]}
                onChange={onChange}
                containerClassName={sharedStyles['big-radio']}
              >
                {'Yes'}
              </BigCheckbox>
              <BigCheckbox
                type="radio"
                name="are_you_respondent"
                value={StrBoolean.false}
                checked={!StrToBoolean[form.are_you_respondent]}
                onChange={onChange}
                containerClassName={sharedStyles['big-radio']}
              >
                {'No'}
              </BigCheckbox>
            </div>
          </div>
          <UserRoleForm
            hide={StrToBoolean[form.are_you_respondent]}
            describes_who_you_are={form.describes_who_you_are}
            name_of_person_asked_to_request={
              form.name_of_person_asked_to_request
            }
            person_relationship_to_respondent={
              form.person_relationship_to_respondent
            }
            are_authorized_to_represent_respondent={
              form.are_authorized_to_represent_respondent
            }
            onChange={onChange}
            touched={touched}
            errors={errors}
            radioLabelClassName={sharedStyles['radio-label']}
            inputWrapperClassName={sharedStyles['input-wrapper']}
            withMarginClassName={sharedStyles['with-margin']}
            inputsClassName={sharedStyles['inputs']}
            bigRadiosClassName={sharedStyles['big-radios']}
            bigRadioClassName={sharedStyles['big-radio']}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default GeneralInfo
