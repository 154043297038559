import React, { useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'

import Header from '@common/Header'
import Link from '@components/Link'
import SearchForm from './SearchForm'
import Filters from './Filters'

import violationsLinks from './links'

import SearchIcon from '@assets/search.svg'

import styles from './styles.module.scss'

const ViolationsWrapper = <
  Props extends RouteComponentProps = RouteComponentProps
>(
  Component: React.ComponentType<Props>
) => {
  const Wrapped: React.FC<Props> = (props) => {
    const [filtersOpen, setFiltersOpen] = useState(false)
    const [filtersActive, setFiltersActive] = useState(false)
    const [searchActive, setSearchActive] = useState(false)

    const openFilters = () => setFiltersOpen(true)
    const closeFilters = () => setFiltersOpen(false)

    const links = useMemo(
      () =>
        violationsLinks.map(({ className, ...link }, index) => (
          <li key={`${link.to}-${index}`} className={styles['link-item']}>
            <Link
              {...link}
              className={clsx(
                'link',
                styles['link'],
                !filtersActive &&
                  !searchActive &&
                  window.location.pathname === link.to &&
                  styles['link-active'],
                className
              )}
            />
          </li>
        )),
      [window.location.pathname, filtersActive, searchActive]
    )

    return (
      <div className={clsx('page', styles['violations'])}>
        <Header />
        <div className={clsx('page-content', styles['page-content'])}>
          <div className={styles['heading']}>
            <h1>{'Violations'}</h1>
            <nav className={styles['navigation']}>
              <ul className={styles['links']}>{links}</ul>
              {(filtersActive || searchActive) && (
                <div className={styles['filters-active']}>
                  {searchActive && !filtersActive && '↙ Search is active'}
                  {filtersActive && !searchActive && 'Filters are active ➞'}
                  {filtersActive &&
                    searchActive &&
                    '↙ Search and Filters are active ➞'}
                </div>
              )}
              <button className="primary-button" onClick={openFilters}>
                {'Filter'}
              </button>
            </nav>
          </div>
          <div className={styles['search-wrapper']}>
            <ReactSVG
              src={SearchIcon}
              className={clsx('svg', styles['search-icon'])}
            />
            <SearchForm setSearchActive={setSearchActive} />
          </div>
          <Component {...props} />
        </div>
        <Filters
          open={filtersOpen}
          setFiltersActive={setFiltersActive}
          close={closeFilters}
        />
      </div>
    )
  }

  return Wrapped
}

export default ViolationsWrapper
