export enum ReasonForGranted {
  within60days = 'within60days',
  morethan60days = 'morethan60days',
  morethan1year = 'morethan1year',
}

interface RadioInfo {
  value: ReasonForGranted
  label: string
}

const radios: RadioInfo[] = [
  {
    value: ReasonForGranted.within60days,
    label:
      'This request is the first request AND it is being filed within ' +
      '60 days from the mailing date or hand delivery date of the Default Decision',
  },
  {
    value: ReasonForGranted.morethan60days,
    label:
      'This request is the first request AND it is being filed more than ' +
      '60 days from the mailing date or hand delivery date of the ' +
      'Default Decision but within 1 year of the date of the Default Decision',
  },
  {
    value: ReasonForGranted.morethan1year,
    label:
      'This request is being filed more than 1 year from the date of the ' +
      'Default Decision or this is not the first hearing date that the ' +
      'Respondent missed on this Notice/Summons number',
  },
]

export default radios
