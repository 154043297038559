import ActionType, { Action } from './types'

import { defaultPerPage } from '@config'

export interface IState {
  isLoading: boolean
  infractionCodes: InfractionCode[]
  infractionCodeDetails: InfractionCode | null
  params: InfractionCodesParams
  error: ErrorType | null
}

const initialState: IState = {
  isLoading: false,
  infractionCodes: [],
  infractionCodeDetails: null,
  params: {
    page: 0,
    per_page: defaultPerPage,
    totalPages: 0,
  },

  error: null,
}

const reducer = (state = initialState, action: Action): IState => {
  switch (action.type) {
    case ActionType.LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      }
    }
    case ActionType.RESET: {
      return {
        ...initialState,
      }
    }
    case ActionType.LOAD_BEGIN: {
      return {
        ...state,
        isLoading: true,
      }
    }
    case ActionType.LOAD_SUCCESS: {
      const { infractionCodes, params } = action.payload
      return {
        ...state,
        infractionCodes,
        params: {
          ...state.params,
          ...params,
        },
        isLoading: false,
      }
    }
    case ActionType.LOAD_DETAILS_SUCCESS: {
      const { infractionCodeDetails } = action.payload
      return {
        ...state,
        infractionCodeDetails,
        isLoading: false,
      }
    }
    case ActionType.ERROR:
    case ActionType.LOAD_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default reducer
