import { requiredValidate, validate } from '@services/validation'
import { FormikErrors } from 'formik'

interface FormData {
  describes_who_you_are?: FormAuthorRoleType | undefined
  name_of_person_asked_to_request?: string
  person_relationship_to_respondent?: string
  are_authorized_to_represent_respondent?: StrBoolean
}

export const validateForm = (namedAsRespondent: boolean = true) => ({
  describes_who_you_are,
  name_of_person_asked_to_request,
  person_relationship_to_respondent,
  are_authorized_to_represent_respondent,
}: FormData): FormikErrors<FormData> =>
  namedAsRespondent
    ? {}
    : {
        describes_who_you_are: validate(
          describes_who_you_are,
          requiredValidate
        ),
        name_of_person_asked_to_request: validate(
          name_of_person_asked_to_request,
          requiredValidate
        ),
        person_relationship_to_respondent: validate(
          person_relationship_to_respondent,
          requiredValidate
        ),
        are_authorized_to_represent_respondent: validate(
          are_authorized_to_represent_respondent,
          requiredValidate
        ),
      }

export default FormData
