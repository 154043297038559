import api from '@services/api'

import action from '@redux/action'
import ActionType from './types'

import { apiRoutes, defaultPerPage } from '@config'
import { SortDirection } from '@typings/enums'

const loading = (isLoading = true): AppThunk => (dispatch) =>
  dispatch(action(ActionType.LOADING, isLoading))

const error = (error: ErrorType): AppThunk => (dispatch) =>
  dispatch(action(ActionType.ERROR, error))

const reset = (): AppThunk => (dispatch) => dispatch(action(ActionType.RESET))

const load = (
  { page, per_page, ...params }: UsersApiLoadOptions = {
    page: undefined,
    per_page: undefined,
  },
  reload?: boolean,
  onSuccess?: (response: UsersApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<UsersApiLoadData> => async (dispatch, getState) => {
  dispatch(action(ActionType.LOAD_BEGIN, { page, per_page, ...params }))

  if (!per_page) {
    per_page = getState().users.params.per_page || defaultPerPage
  }

  if (reload === undefined) {
    reload = true
  }

  if (page === undefined) {
    if (reload) {
      page = 1
    } else {
      page = Math.max((getState().users.params.page || 0) + 1, 1)
    }
  }

  try {
    const response = await api.get<ApiSuccessResponse<UsersApiLoadData>>(
      apiRoutes.users,
      {
        params: { ...getState().users.params, ...params, per_page, page },
      }
    )

    dispatch(
      action(ActionType.LOAD_SUCCESS, {
        users: response.data.data.records,
        params: {
          ...params,
          page,
          per_page,
          reload,
          totalPages: response.data.data.meta.lastPage,
        },
      })
    )

    onSuccess?.(response.data.data)
    return response.data.data
  } catch (error) {
    dispatch(action(ActionType.LOAD_ERROR, error))
    onError?.(error)
  }
}

const sort = (
  params: UsersApiLoadOptions = {},
  reload?: boolean,
  onSuccess?: (response: UsersApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<UsersApiLoadData> => (dispatch, getState) => {
  const prevParams = getState().users.params

  params.order_by_direction =
    params?.order_by_direction ||
    (prevParams.order_by === params.order_by &&
      prevParams.order_by_direction === SortDirection.asc)
      ? SortDirection.desc
      : SortDirection.asc

  params.page = params.page || prevParams.page
  params.per_page = params.per_page || getState().users.users.length

  reload = reload === undefined ? true : undefined

  return load(params, reload, onSuccess, onError)(dispatch, getState, undefined)
}

const filter = (
  filters: UsersFilters,
  onSuccess?: (response: UsersApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<UsersApiLoadData> => (dispatch, getState) =>
  load(
    { ...filters, page: 1 },
    undefined,
    onSuccess,
    onError
  )(dispatch, getState, undefined)

const loadDetails = (
  id: ApiID,
  onSuccess?: (response: User) => void,
  onError?: (error: ErrorType) => void
): AppThunk<User> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_DETAILS_BEGIN))

  try {
    const response = await api.get<ApiSuccessResponse<User>>(
      apiRoutes.userDetails(id)
    )

    dispatch(action(ActionType.LOAD_DETAILS_SUCCESS, response.data.data))

    onSuccess?.(response.data.data)
    return response.data.data
  } catch (error) {
    dispatch(action(ActionType.LOAD_DETAILS_ERROR, error))
    onError?.(error)
  }
}

const usersActions = {
  loading,
  error,
  reset,

  load,
  sort,
  filter,

  loadDetails,
}

export default usersActions
