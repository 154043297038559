import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import Header from '@common/Header'
import Head from './Head'

import styles from './styles.module.scss'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import QuestionItem from './QuestionItem'
import EditableDefence from './EditableDefence'
import Dropzone from '@pages/DefenceQuizzes/Dropzone'
import QuizModal from './QuizModal'
import QuizItem from '@pages/DefenceQuizzes/QuizItem'
import { ReactSVG } from 'react-svg'
import DnD from '@assets/dnd-placeholder.svg'
import { toast } from 'react-toastify'

interface Props {
  goBack: () => void
  replace: (path: string) => void
  infractionCode: InfractionCode | null
  saveQuiz: Function
  saveInfractionCode: Function
  deleteQuiz: Function
}

export const question: Question = {
  question: '',
  orderNumber: 1,
  inDefence: false,
  answers: ['', '', ''],
}
const questions: Question[] = [question]

const initialQuiz: Quiz = {
  infraction_code_id: null,
  name: '',
  defence: null,
  questions,
  automated: false,
}

const View: React.FC<Props> = ({
  goBack,
  infractionCode,
  saveQuiz,
  deleteQuiz,
}) => {
  const [lastDragHoveredElement, setLastDragHoveredElement] = useState(null)
  const [modelOpened, setModalOpened] = useState(false)
  const [currentQuiz, setCurrentQuiz] = useState<Quiz | null>(
    infractionCode?.quizzes.length ? infractionCode?.quizzes[0] : initialQuiz
  )
  useEffect(() => {
    const quiz = infractionCode?.quizzes.length
      ? infractionCode?.quizzes[0]
      : initialQuiz
    setCurrentQuiz(quiz)
  }, [infractionCode])

  const handleNewQuiz = (quiz: Quiz) => {
    setCurrentQuiz({
      ...quiz,
      infraction_code_id: infractionCode?.id || null,
    })
    setModalOpened(true)
  }

  const handleCopyQuiz = (quiz: Quiz) => {
    handleNewQuiz(quiz)
  }

  const handleDefenceChange = (defence: string, editing: boolean = false) => {
    if (!editing) {
      toast('Please do nor forget to press "Save Defence"')
    }
    // @ts-ignore
    setCurrentQuiz({ ...currentQuiz, defence })
  }

  const handleSaveDefence = () => {
    if (!currentQuiz?.defence) {
      toast('Enter defense text first')
    }
    saveQuiz(currentQuiz)
  }

  const handleSaveInfraction = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!currentQuiz?.defence || !currentQuiz?.questions) {
      e.preventDefault()
      toast.error('Create quiz and defence for it first')
      return
    } else {
      saveQuiz({
        ...currentQuiz,
        automated: !currentQuiz?.automated,
      })
    }
  }

  return (
    <div className={clsx('page', styles['defence-quizzes-details'])}>
      <Header />
      {infractionCode && (
        <DndProvider backend={HTML5Backend}>
          <div
            key={infractionCode.code}
            className={clsx(styles['page-content'])}
          >
            <Head infractionCode={infractionCode} goBack={goBack} />
            <div className={styles['defence-head-divider']} />
            <div className={styles['quizzes-list']}>
              {infractionCode.quizzes &&
                infractionCode.quizzes.map((quiz, index) => (
                  <QuizItem
                    key={index}
                    isSelected={currentQuiz?.id == quiz.id}
                    handleCopyQuiz={(quiz: Quiz) => handleCopyQuiz(quiz)}
                    setModalOpened={setModalOpened}
                    setCurrentQuiz={setCurrentQuiz}
                    deleteQuiz={() => deleteQuiz(currentQuiz)}
                    quiz={quiz}
                    initialQuiz={initialQuiz}
                  />
                ))}
            </div>
            <button
              onClick={() => handleNewQuiz(initialQuiz)}
              className={styles['add-new-quiz']}
            >
              + New Quiz
            </button>
            <div className={styles['defence-section']}>
              <div className={styles['defence-header']}>
                <h3>Defence</h3>
                <div className={styles['automated-switch']}>
                  <label className={styles['switch']}>
                    <input
                      key={
                        currentQuiz
                          ? currentQuiz?.id + currentQuiz?.name
                          : 'new quiz'
                      }
                      onClick={(e) => handleSaveInfraction(e)}
                      defaultChecked={currentQuiz?.automated}
                      type="checkbox"
                    />
                    <span className={clsx(styles['slider'], styles['round'])} />
                  </label>
                  <div>Automated</div>
                </div>
              </div>
              <Dropzone
                setLastDragHoveredElement={setLastDragHoveredElement}
                name={'questions'}
              >
                <EditableDefence
                  questions={currentQuiz?.questions}
                  saveDefence={handleSaveDefence}
                  handleDefenceChange={handleDefenceChange}
                  defence={currentQuiz?.defence}
                />
              </Dropzone>
            </div>
            <div className={styles['questions-list']}>
              <Dropzone
                setLastDragHoveredElement={setLastDragHoveredElement}
                name={'questions'}
              >
                <h3>Questions</h3>
                <div className={styles['questions']}>
                  {currentQuiz &&
                    currentQuiz.id &&
                    currentQuiz.questions
                      .sort((a, b) => a.orderNumber - b.orderNumber)
                      .map((quiz, index) => (
                        <QuestionItem
                          handleSaveDefence={handleDefenceChange}
                          defence={currentQuiz.defence}
                          key={index}
                          question={quiz}
                          lastDragHoveredElement={lastDragHoveredElement}
                        />
                      ))}
                </div>
              </Dropzone>
            </div>
            <div className={styles['dnd-hint']}>
              <ReactSVG src={DnD} className={clsx('svg', styles['logo'])} />
              use drag and drop function to put questions into defence text
            </div>
          </div>
          {currentQuiz && (
            <QuizModal
              saveQuiz={saveQuiz}
              currentQuiz={currentQuiz}
              setCurrentQuiz={setCurrentQuiz}
              open={modelOpened}
              onRequestClose={() => setModalOpened(false)}
            />
          )}
        </DndProvider>
      )}
    </div>
  )
}

export default View
