import React from 'react'
import styles from '../styles.module.scss'
import { ReactSVG } from 'react-svg'
import clsx from 'clsx'
import Copy from '@assets/copy.svg'
import Edit from '@assets/edit.svg'
import Delete from '@assets/delete.svg'

interface Props {
  quiz: Quiz
  initialQuiz: Quiz
  isSelected: boolean
  setModalOpened: Function
  setCurrentQuiz: Function
  handleCopyQuiz: (quiz: Quiz) => void
  deleteQuiz: Function
}

const QuizItem: React.ComponentType<Props> = ({
  isSelected,
  setModalOpened,
  setCurrentQuiz,
  handleCopyQuiz,
  deleteQuiz,
  quiz,
  initialQuiz,
}) => {
  return (
    <div className={styles['quiz-item']}>
      <div className={styles['header']}>
        <button
          onClick={() => setCurrentQuiz(quiz)}
          className={styles['title']}
        >
          {quiz.name}
        </button>
        {isSelected && (
          <div className={styles['control-panel']}>
            <button>
              <ReactSVG
                src={Copy}
                onClick={() =>
                  handleCopyQuiz({
                    ...initialQuiz,
                    name: quiz.name,
                    questions: quiz.questions,
                    defence: quiz.defence,
                  })
                }
                className={clsx('svg', styles['logo'])}
              />
            </button>
            <button>
              <ReactSVG
                src={Edit}
                onClick={() => {
                  setCurrentQuiz(quiz)
                  setModalOpened(true)
                }}
                className={clsx('svg', styles['logo'])}
              />
            </button>
            <button>
              <ReactSVG
                onClick={() => deleteQuiz()}
                src={Delete}
                className={clsx('svg', styles['logo'])}
              />
            </button>
          </div>
        )}
      </div>
      {quiz.defence && <div className={styles['subtitle']}>Defence added</div>}
      {!quiz.defence && (
        <div className={styles['subtitle-active']}>Waiting for defence</div>
      )}
    </div>
  )
}

export default QuizItem
