export const defaultShortDateFormat = 'm-d-Y'
export const defaultDateFormat = 'mm-dd-YYYY'
export const nycDateFormat = 'M d, Y'
export const nycDateFormatFNS = 'MMM dd, Y'

export default {
  defaultShortDateFormat,
  defaultDateFormat,
  nycDateFormat,
}
