import React from 'react'
import Paper from '@components/Paper'
import styles from '../../styles.module.scss'
import links from '@routes/links'
import Link from '@components/Link'

interface Props {
  item: InfractionCode
}

const addQuizClassSelector = (item: InfractionCode) =>
  item.defencesNumber || item.quizzesNumber ? 'button' : 'button-clickbait'

const PaperItem: React.FC<Props> = ({ item }) => {
  return (
    <Paper>
      <div className={styles['defences-item']}>
        <div className={styles['heading']}>
          <h2>{item.code}</h2>
          <p className={styles['penalty']}>
            PENALTY
            <p className={styles['bold']}>{item.penalty}</p>
          </p>
        </div>
        <div className={styles['divider']} />
        <div className={styles['content']}>
          <p>{item.description}</p>
          <div className={styles['management-panel']}>
            <div className={styles['management-panel-info']}>
              <p className={styles['row']}>
                <div className={styles['bold']}>{item.quizzesNumber} </div>
                quizes
              </p>
              {!item.defencesNumber && (
                <div className={styles['def-info-clickbait']} />
              )}
              {!!item.defencesNumber && (
                <p className={styles['row']}>
                  <div className={styles['bold-active']}>
                    {item.defencesNumber}
                  </div>
                  def
                </p>
              )}
            </div>
            <Link to={links.defenceQuizzes(item.id)}>
              <button className={styles[addQuizClassSelector(item)]}>
                {item.defencesNumber || item.quizzesNumber
                  ? 'View Defence'
                  : 'Add Defence'}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default PaperItem
