import api from '@services/api'

import action from '@redux/action'
import ActionType from './types'

import { apiRoutes, defaultPerPage } from '@config'

const loading = (isLoading = true): AppThunk => (dispatch) =>
  dispatch(action(ActionType.LOADING, isLoading))

const error = (error: ErrorType): AppThunk => (dispatch) =>
  dispatch(action(ActionType.ERROR, error))

const reset = (): AppThunk => (dispatch) => dispatch(action(ActionType.RESET))

const load = (
  { page, per_page, ...params }: BuildingsApiLoadOptions = {
    page: undefined,
    per_page: undefined,
  },
  reload?: boolean,
  onSuccess?: (response: BuildingsApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<BuildingsApiLoadData> => async (dispatch, getState) => {
  dispatch(action(ActionType.LOAD_BEGIN))

  if (!per_page) {
    per_page = getState().violations.params.per_page || defaultPerPage
  }

  if (reload === undefined) {
    reload = true
  }

  if (page === undefined) {
    if (reload) {
      page = 1
    } else {
      page = Math.max((getState().violations.params.page || 0) + 1, 1)
    }
  }

  try {
    const response = await api.get<ApiSuccessResponse<BuildingsApiLoadData>>(
      apiRoutes.buildings,
      {
        params: { per_page, page, ...params },
      }
    )

    dispatch(
      action(ActionType.LOAD_SUCCESS, {
        buildings: response.data.data.records,
        params: {
          ...params,
          page,
          per_page,
          reload,
          totalPages: response.data.data.meta.lastPage,
        },
      })
    )

    onSuccess?.(response.data.data)
    return response.data.data
  } catch (error) {
    dispatch(action(ActionType.LOAD_ERROR, error))
    onError?.(error)
  }
}

const buildingsActions = {
  loading,
  error,
  reset,

  load,
}

export default buildingsActions
