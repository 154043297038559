import React, { useCallback } from 'react'
import { FormikErrors, FormikTouched } from 'formik'
import clsx from 'clsx'

import Input from '@components/Input'
import Select, { ValueType } from '@components/Select'

import FormData from './form'
import statesOptions from '@constants/statesOptions'
import { phoneMask } from '@constants/mask'

import sharedStyles from '../styles.module.scss'

interface Props {
  form: FormData
  touched: FormikTouched<FormData>
  errors: FormikErrors<FormData>
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setFieldValue: <F extends keyof FormData = keyof FormData>(
    field: F,
    value: FormData[F],
    shouldValidate?: boolean
  ) => void
}

const DecisionAddress: React.FC<Props> = ({
  form,
  touched,
  errors,
  onChange,
  setFieldValue,
}) => {
  const onStateChange = useCallback(
    (e: ValueType<State>) => setFieldValue('state', e, false),
    [setFieldValue]
  )

  return (
    <React.Fragment>
      <div className={sharedStyles['section']}>
        <div className={sharedStyles['section-head']}>
          <h2 className={sharedStyles['section-title']}>
            {'Mailing Address to Receive Decision'}
          </h2>
          <p className={sharedStyles['section-description']}>
            {'If no valid mailing address is provided, this e-form will ' +
              'be invalid and will not be considered by a hearing officer.'}
          </p>
        </div>
        <div
          className={clsx(
            sharedStyles['inputs'],
            sharedStyles['two-columns'],
            sharedStyles['sub-section']
          )}
        >
          <Input
            type="text"
            name="address"
            value={form.address}
            onChange={onChange}
            label="Address"
            placeholder="Enter Address"
            labelAsterisk={true}
            error={touched.address && errors.address}
          />
          <Input
            type="text"
            name="apartment_number"
            value={form.apartment_number}
            onChange={onChange}
            label="Apartment Number"
            placeholder="Enter Apartment Number"
            error={touched.apartment_number && errors.apartment_number}
          />
          <Input
            type="text"
            name="city"
            value={form.city}
            onChange={onChange}
            label="City"
            placeholder="Enter City"
            labelAsterisk={true}
            error={touched.city && errors.city}
          />
          <Select
            value={form.state}
            onChange={onStateChange}
            options={statesOptions}
            label="State"
            placeholder="Enter State"
            labelAsterisk={true}
            error={touched.state && errors.state}
          />
          <Input
            type="number"
            maxLength={5}
            name="zip_code"
            value={form.zip_code}
            onChange={onChange}
            label="ZIP Code"
            placeholder="Enter ZIP Code"
            labelAsterisk={true}
            error={touched.zip_code && errors.zip_code}
          />
        </div>
        <div
          className={clsx(
            sharedStyles['inputs'],
            sharedStyles['two-columns'],
            sharedStyles['sub-section'],
            sharedStyles['no-border']
          )}
        >
          <Input
            type="tel"
            name="phone"
            value={form.phone}
            onChange={onChange}
            label="Phone"
            placeholder="Enter Phone"
            labelAsterisk={true}
            mask={phoneMask}
            error={touched.phone && errors.phone}
          />
          <Input
            type="email"
            name="email"
            value={form.email}
            onChange={onChange}
            label="Email"
            placeholder="Enter Email"
            labelAsterisk={true}
            error={touched.email && errors.email}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default DecisionAddress
