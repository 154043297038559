import api, { haveToLoadStatics } from '@services/api'

import action from '@redux/action'
import ActionType from './types'

import { apiRoutes, defaultPerPage } from '@config'
import { SortDirection, StrToBoolean } from '@typings/enums'
import { emptyFilter } from '@services/violation'
import { getState } from '@services/redux'
import { toFormData } from '@services/forms'
import { toast } from 'react-toastify'

const loading = (isLoading = true): AppThunk => (dispatch) =>
  dispatch(action(ActionType.LOADING, isLoading))

const error = (error: ErrorType): AppThunk => (dispatch) =>
  dispatch(action(ActionType.ERROR, error))

const reset = (): AppThunk => (dispatch) => dispatch(action(ActionType.RESET))

const load = (
  { page, per_page, type, ...params }: ViolationsApiLoadOptions = {},
  reload?: boolean,
  withoutType?: boolean,
  onSuccess?: (response: ViolationApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<ViolationApiLoadData> => async (dispatch, getState) => {
  dispatch(action(ActionType.LOAD_BEGIN, { page, per_page, type, ...params }))

  if (!type && !withoutType) {
    type = getState().violations.params.type
  }
  if (withoutType) {
    type = undefined
  }

  if (!per_page) {
    per_page = getState().violations.params.per_page || defaultPerPage
  }

  if (
    reload === undefined &&
    (type !== getState().violations.params.type || page === 1)
  ) {
    reload = true
  }

  if (page === undefined) {
    if (reload) {
      page = 1
    } else {
      page = Math.max((getState().violations.params.page || 0) + 1, 1)
    }
  }

  try {
    const response = await api.get<ApiSuccessResponse<ViolationApiLoadData>>(
      apiRoutes.violations,
      {
        params: {
          ...getState().violations.params,
          ...params,
          totalPages: undefined,
          reload: undefined,
          type,
          per_page,
          page,
        },
      }
    )

    dispatch(
      action(ActionType.LOAD_SUCCESS, {
        violations: response.data.data.records,
        params: {
          page,
          per_page,
          reload,
          type,
          totalPages: response.data.data.meta.lastPage,
          ...params,
        },
      })
    )

    onSuccess?.(response.data.data)
    return response.data.data
  } catch (error) {
    dispatch(action(ActionType.LOAD_ERROR, error))
    onError?.(error)
  }
}

const sort = (
  params: ViolationsApiLoadOptions = {},
  reload?: boolean,
  onSuccess?: (response: ViolationApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<ViolationApiLoadData> => (dispatch, getState) => {
  const prevParams = getState().violations.params
  const prevViolations = getState().violations.violations

  params.order_by_direction =
    params?.order_by_direction ||
    (prevParams.order_by === params.order_by &&
      prevParams.order_by_direction === SortDirection.asc)
      ? SortDirection.desc
      : SortDirection.asc

  params.page = params.page || prevParams.page
  params.per_page =
    params.per_page || Array.isArray(prevViolations)
      ? (prevViolations.length as number)
      : defaultPerPage

  reload = reload === undefined ? true : undefined

  return load(
    params,
    reload,
    false,
    onSuccess,
    onError
  )(dispatch, getState, undefined)
}

const filter = (
  filters: ViolationFilters & { type?: ViolationType },
  onSuccess?: (response: ViolationApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<ViolationApiLoadData> => (dispatch, getState) =>
  load(
    { ...filters, page: 1 },
    undefined,
    !(filters.type && filters.type.length > 0),
    onSuccess,
    onError
  )(dispatch, getState, undefined)

const resetFilters = (
  onSuccess?: (response: ViolationApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<ViolationApiLoadData> => (dispatch, getState) =>
  load(
    { ...emptyFilter, page: 1 },
    undefined,
    true,
    onSuccess,
    onError
  )(dispatch, getState, undefined)

const loadDetails = (
  id: ApiID,
  onSuccess?: (response: ViolationDetails) => void,
  onError?: (error: ErrorType) => void
): AppThunk<ViolationDetails> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_DETAILS_BEGIN))

  try {
    const response = await api.get<ApiSuccessResponse<ViolationDetails>>(
      apiRoutes.violationDetails(id),
      { params: { type: 'for admin' } }
    )

    dispatch(action(ActionType.LOAD_DETAILS_SUCCESS, response.data.data))

    onSuccess?.(response.data.data)
    return response.data.data
  } catch (error) {
    dispatch(action(ActionType.LOAD_DETAILS_ERROR, error))
    onError?.(error)
  }
}

const update = (
  id: ApiID,
  updateBody: ViolationUpdateBody = {},
  onSuccess?: (response: ViolationDetails) => void,
  onError?: (error: ErrorType) => void
): AppThunk<ViolationDetails> => async (dispatch) => {
  dispatch(action(ActionType.UPDATE_BEGIN))

  try {
    const response = await api.put<ApiSuccessResponse<ViolationDetails>>(
      apiRoutes.violationDetails(id),
      updateBody
    )

    dispatch(action(ActionType.UPDATE_SUCCESS, response.data.data))

    onSuccess?.(response.data.data)
    return response.data.data
  } catch (error) {
    dispatch(action(ActionType.UPDATE_ERROR, error))
    onError?.(error)
  }
}

const loadInfractionCodes = (
  params: ViolationInfractionCodesLoadOptions = {},
  onSuccess?: (response: string[]) => void,
  onError?: (error: ErrorType) => void
): AppThunk<string[]> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_INF_CODES_BEGIN))

  try {
    const response = await api.get<
      ApiSuccessResponse<ViolationInfractionCodesLoadData>
    >(apiRoutes.violationsInfractionCodes)

    dispatch(
      action(ActionType.LOAD_INF_CODES_SUCCESS, {
        infCodes: response.data.data.records[0],
        params,
      })
    )

    onSuccess?.(getState().violations.infCodes)
    return getState().violations.infCodes
  } catch (error) {
    dispatch(action(ActionType.LOAD_INF_CODES_ERROR, error))
    onError?.(error)
  }
}

const loadHearingResults = (
  onSuccess?: (response: HearingStatusType[]) => void,
  onError?: (error: ErrorType) => void
): AppThunk<HearingStatusType[]> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_HEARING_RESULTS_BEGIN))

  try {
    if (haveToLoadStatics(getState().violations.hearingResultsLoadedAt)) {
      const response = await api.get<
        ApiSuccessResponse<ViolationHearingResultsLoadData>
      >(apiRoutes.violationsHearingResults)

      dispatch(
        action(
          ActionType.LOAD_HEARING_RESULTS_SUCCESS,
          response.data.data.records[0]
        )
      )
    } else {
      dispatch(
        action(
          ActionType.LOAD_HEARING_RESULTS_SUCCESS,
          getState().violations.hearingResults
        )
      )
    }

    onSuccess?.(getState().violations.hearingResults)
    return getState().violations.hearingResults
  } catch (error) {
    dispatch(action(ActionType.LOAD_HEARING_RESULTS_ERROR, error))
    onError?.(error)
  }
}

const loadCaseStatuses = (
  onSuccess?: (response: CaseStatusTypeKey[]) => void,
  onError?: (error: ErrorType) => void
): AppThunk<CaseStatusTypeKey[]> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_CASE_STATUSES_BEGIN))

  try {
    if (haveToLoadStatics(getState().violations.caseStatusKeysLoadedAt)) {
      const response = await api.get<
        ApiSuccessResponse<ViolationCaseStatusesLoadData>
      >(apiRoutes.violationsCaseStatuses)

      const keys = response.data.data.records.map((rec) => rec.key)

      dispatch(action(ActionType.LOAD_CASE_STATUSES_SUCCESS, keys))
    } else {
      dispatch(
        action(
          ActionType.LOAD_CASE_STATUSES_SUCCESS,
          getState().violations.caseStatusKeys
        )
      )
    }

    onSuccess?.(getState().violations.caseStatusKeys)
    return getState().violations.caseStatusKeys
  } catch (error) {
    dispatch(action(ActionType.LOAD_CASE_STATUSES_ERROR, error))
    onError?.(error)
  }
}

const loadActivity = (
  id: ApiID,
  onSuccess?: (response: ViolationActivity[]) => void,
  onError?: (error: ErrorType) => void
): AppThunk<ViolationActivity[]> => async (dispatch) => {
  dispatch(action(ActionType.LOAD_ACTIVITY_BEGIN))

  try {
    const response = await api.get<
      ApiSuccessResponse<ViolationActivityLoadData>
    >(apiRoutes.violationActivity(id))

    dispatch(
      action(ActionType.LOAD_ACTIVITY_SUCCESS, response.data.data.records)
    )

    onSuccess?.(getState().violations.activity)
    return getState().violations.activity
  } catch (error) {
    dispatch(action(ActionType.LOAD_ACTIVITY_ERROR, error))
    onError?.(error)
  }
}

const sendReopenRequest = (
  data: ViolationReopenRequestForm,
  onSuccess?: (response: ViolationReopenRequestApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<ViolationReopenRequestApiLoadData> => async (dispatch) => {
  dispatch(action(ActionType.SEND_REOPEN_REQUEST_BEGIN))

  if (!data.respondents_email) {
    delete data.respondents_email
  }

  if (!data.respondents_phone) {
    delete data.respondents_phone
  }

  if (StrToBoolean[data.are_you_respondent]) {
    delete data.describes_who_you_are
    delete data.name_of_person_asked_to_request
    delete data.person_relationship_to_respondent
    delete data.are_authorized_to_represent_respondent
  }

  try {
    const formData = toFormData(data)

    const response = await api.post<
      ApiSuccessResponse<ViolationReopenRequestApiLoadData>
    >(apiRoutes.violationSendReopenRequest, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    dispatch(action(ActionType.SEND_REOPEN_REQUEST_SUCCESS))
    toast('Submitted successfully')
    window.history.back()
    onSuccess?.(response.data.data)

    return response.data.data
  } catch (error) {
    if (error.response?.data?.errors) {
      const errors = error.response.data.errors
      const keys = Object.keys(errors)
      if (keys[0] && errors[keys[0]]) {
        toast(errors[keys[0]][0])
      }
    }
    dispatch(action(ActionType.SEND_REOPEN_REQUEST_ERROR, error))
    onError?.(error)
  }
}

const sendOnlineHearing = (
  data: ViolationOnlineHearingForm,
  onSuccess?: (response: ViolationOnlineHearingApiLoadData) => void,
  onError?: (error: ErrorType) => void
): AppThunk<ViolationOnlineHearingApiLoadData> => async (dispatch) => {
  dispatch(action(ActionType.SEND_ONLINE_HEARING_BEGIN))

  if (StrToBoolean[data.are_you_respondent]) {
    delete data.describes_who_you_are
    delete data.name_of_person_asked_to_request
    delete data.person_relationship_to_respondent
    delete data.are_authorized_to_represent_respondent
  }

  try {
    const formData = toFormData(data)

    const response = await api.post<
      ApiSuccessResponse<ViolationOnlineHearingApiLoadData>
    >(apiRoutes.violationSendOnlineHearing, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    dispatch(action(ActionType.SEND_ONLINE_HEARING_SUCCESS))

    onSuccess?.(response.data.data)
    return response.data.data
  } catch (error) {
    dispatch(action(ActionType.SEND_ONLINE_HEARING_ERROR, error))
    onError?.(error)
  }
}

const violationsActions = {
  loading,
  error,
  reset,

  load,
  sort,
  filter,
  resetFilters,

  loadDetails,
  update,
  loadActivity,

  loadInfractionCodes,
  loadHearingResults,
  loadCaseStatuses,

  sendReopenRequest,
  sendOnlineHearing,
}

export default violationsActions
