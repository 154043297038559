import React from 'react'
import styles from '../styles.module.scss'
import PaperItem from '@pages/Defences/InfractionCodesList/InfractionCodePaper'

interface Props {
  items: InfractionCode[]
}

const List: React.FC<Props> = ({ items }) => {
  return (
    <div className={styles['defences-list']}>
      {items.map((item) => (
        <PaperItem key={item.id} item={item} />
      ))}
    </div>
  )
}

export default List
