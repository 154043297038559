import React, { useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import { isId } from '@services/utils'
import violationsActions from '@redux/violations/actions'
import { CaseStatusType, ViolationDefenseType } from '@typings/enums'

import View from './view'

const mapStateToProps = (state: ReduxState) => ({
  violationId: state.violations.violation?.id,
  defenceType: state.violations.violation?.defenceType,
  caseStatus: state.violations.violation?.caseStatus,
})

const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  violationsActions: bindActionCreators<
    typeof violationsActions,
    BindedAsyncActions<typeof violationsActions>
  >(violationsActions, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type NativeProps = RouteComponentProps<{ id: string }>

type Props = NativeProps & ConnectedProps<typeof connector>

const ViolationRequestReopen: React.FC<Props> = ({
  match: {
    params: { id },
  },
  history: { goBack },
  violationId,
  defenceType,
  caseStatus,
  violationsActions: { loadDetails },
}) => {
  useEffect(() => {
    if (
      !isId(id) ||
      defenceType !== ViolationDefenseType.defaulted ||
      caseStatus === CaseStatusType.processingMotion ||
      caseStatus === CaseStatusType.processingHearing
    ) {
      goBack()
    } else if (Number(id) !== violationId) {
      loadDetails(Number(id))
    }
  }, [id, violationId, loadDetails])

  return <View goBack={goBack} />
}

export default connector(ViolationRequestReopen) as React.FC<NativeProps>
