import { requiredValidate, someCheckbox, validate } from '@services/validation'
import { FormikErrors } from 'formik'
import { ReasonForGranted } from './radios'
import { nycDateFormatFNS } from '@constants/date'
import format from 'date-fns/format'

interface FormData {
  date_respondent_learn_about_summons: string
  how_respondent_learned_about_summons: string
  reason_to_grant_new_hearing: ReasonForGranted

  reasonable_excuse: [
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean,
    boolean
  ]

  respondents_connection_to_property_and_when_ended?: string
  explain?: string
  explain_what_circumstances?: string
}

export const formInitial: FormData = {
  date_respondent_learn_about_summons: format(new Date(), nycDateFormatFNS),
  how_respondent_learned_about_summons: 'Search on NYC Open Data',
  reason_to_grant_new_hearing: ReasonForGranted.within60days,

  reasonable_excuse: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],

  respondents_connection_to_property_and_when_ended: '',
  explain: '',
  explain_what_circumstances: '',
}

export const validateForm = ({
  date_respondent_learn_about_summons,
  how_respondent_learned_about_summons,
  reason_to_grant_new_hearing,

  reasonable_excuse,

  explain_what_circumstances,
  explain,
  respondents_connection_to_property_and_when_ended,
}: FormData): FormikErrors<FormData> => ({
  date_respondent_learn_about_summons: validate(
    date_respondent_learn_about_summons,
    requiredValidate
  ),
  how_respondent_learned_about_summons: validate(
    how_respondent_learned_about_summons,
    requiredValidate
  ),
  reason_to_grant_new_hearing: validate(
    reason_to_grant_new_hearing,
    requiredValidate
  ),

  reasonable_excuse:
    reason_to_grant_new_hearing === ReasonForGranted.morethan60days
      ? validate<boolean[]>(
          reasonable_excuse,
          someCheckbox(...reasonable_excuse)
        )
      : undefined,

  respondents_connection_to_property_and_when_ended:
    reason_to_grant_new_hearing === ReasonForGranted.morethan60days &&
    reasonable_excuse[6]
      ? validate(
          respondents_connection_to_property_and_when_ended,
          requiredValidate
        )
      : undefined,

  explain:
    reason_to_grant_new_hearing === ReasonForGranted.morethan60days &&
    reasonable_excuse[8]
      ? validate(explain, requiredValidate)
      : undefined,

  explain_what_circumstances:
    reason_to_grant_new_hearing === ReasonForGranted.morethan1year
      ? validate(explain_what_circumstances, requiredValidate)
      : undefined,
})

export const formKeys: (keyof FormData)[] = [
  'date_respondent_learn_about_summons',
  'how_respondent_learned_about_summons',
  'reason_to_grant_new_hearing',
  'reasonable_excuse',
  'respondents_connection_to_property_and_when_ended',
  'explain_what_circumstances',
  'explain',
]

export default FormData
