import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import Header from '@common/Header'

import styles from './styles.module.scss'
import List from './InfractionCodesList'
import { ReactSVG } from 'react-svg'
import SearchIcon from '@assets/search.svg'
import Input from '@components/Input'

interface Props {
  infractionCodes: InfractionCode[]
  pagination: React.ReactNode
}

const View: React.FC<Props> = ({ infractionCodes, pagination }) => {
  const [codes, setCodes] = useState(infractionCodes)

  useEffect(() => setCodes(infractionCodes), [infractionCodes])

  return (
    <div className={clsx('defences', styles['defences'])}>
      <Header />
      <div className={clsx('page-content', styles['page-content'])}>
        <div className={styles['heading']}>
          <h1>{'Defences'}</h1>
        </div>
        <div className={styles['search-wrapper']}>
          <ReactSVG
            src={SearchIcon}
            className={clsx('svg', styles['search-icon'])}
          />
          <Input
            type="text"
            name="search"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setCodes(
                infractionCodes.filter((code) =>
                  code.code
                    .toLowerCase()
                    .includes(event.target.value.toLowerCase())
                )
              )
            }
            inputContainerClassName={styles['search']}
            placeholder="search"
            containerClassName={styles['search-container']}
            disableError={true}
          />
        </div>
        <List items={codes} />
        {pagination}
      </div>
    </div>
  )
}

export default View
