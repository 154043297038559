import React from 'react'
import { compose } from 'redux'

import reduxBoot from '@boot/redux'
import {
  configureApi,
  configureAxios,
  configureReduxConsole,
  configureScrollbarPadding,
} from '@services/configure'

import Routes from '@routes'

type Props = {}

class App extends React.Component {
  private cleanup: (() => void) | undefined = undefined
  constructor(props: Props) {
    super(props)
    const cleanupAxios = configureAxios()
    const cleanupApi = configureApi()
    const cleanupConsole = configureReduxConsole()
    configureScrollbarPadding()
    this.cleanup = () => {
      cleanupAxios?.()
      cleanupApi?.()
      cleanupConsole?.()
    }
  }

  componentWillUnmount() {
    this.cleanup?.()
  }

  render() {
    return <Routes />
  }
}

const hocs = [reduxBoot]

export default compose<React.FC>(...hocs)(App)
