import React, { useMemo } from 'react'
import clsx from 'clsx'
import { ReactSVG } from 'react-svg'

import Heading from '@common/Heading'

import MailIcon from '@assets/mail.svg'
import PhoneIcon from '@assets/phone.svg'

import styles from './styles.module.scss'

interface Props {
  firstName?: string
  lastName?: string
  email?: string | null
  phone?: string | null
  goBack: () => void
  className?: string
}

const UserHeadInfo: React.FC<Props> = ({
  firstName,
  lastName,
  email,
  phone,
  goBack,
  className,
}) => {
  const name =
    (firstName || '') + (firstName && lastName ? ' ' : '') + (lastName || '')

  const emailNode = useMemo(
    () =>
      email ? (
        <a
          href={`mailto:${email}`}
          className={clsx(styles['contact-link'], styles['mail-link'])}
        >
          <ReactSVG
            src={MailIcon}
            className={clsx('svg', styles['mail-icon'])}
          />
          {email}
        </a>
      ) : null,
    [email]
  )

  const phoneNode = useMemo(
    () =>
      phone ? (
        <a
          href={`tel:${phone}`}
          className={clsx(styles['contact-link'], styles['phone-link'])}
        >
          <ReactSVG
            src={PhoneIcon}
            className={clsx('svg', styles['phone-icon'])}
          />
          {phone}
        </a>
      ) : null,
    [phone]
  )

  return (
    <section className={clsx(styles['head-info'], className)}>
      <Heading goBack={goBack} className={styles['title-container']}>
        {name}
      </Heading>
      <div className={styles['contacts-container']}>
        {emailNode}
        {phoneNode}
      </div>
    </section>
  )
}

export default UserHeadInfo
