import { reverseCompare } from '@services'
import {
  BalanceLabel,
  PaidStatusType,
  ViolationSortType,
  violationsFilterTypes,
  violationsInfCodesFilterTypes,
} from '@typings/enums'
import { getAmount } from './money'
import { intersection, nosort, objectsAreEqual } from './utils'

export const getStatusType = (v: Violation) => {
  if ('paid' in v && v.paid) {
    return PaidStatusType.paid
  } else if ('balanceType' in v) {
    switch (v.balanceType) {
      case BalanceLabel.fee: {
        return PaidStatusType.unpaidFee
      }
      case BalanceLabel.fine:
      default: {
        return PaidStatusType.unpaid
      }
    }
  } else {
    return PaidStatusType.unpaid
  }
}

export const hearingDateCompare: ViolationSort = (
  a: Violation,
  b: Violation
) => {
  const aHearingDate =
    'hearingDate' in a ? new Date(a.hearingDate).getTime() : 0
  const bHearingDate =
    'hearingDate' in b ? new Date(b.hearingDate).getTime() : 0

  return aHearingDate - bHearingDate
}

export const balanceCompare: ViolationSort = (a: Violation, b: Violation) => {
  const aBalance = 'balance' in a ? getAmount(a.balance) : 0
  const bBalance = 'balance' in b ? getAmount(b.balance) : 0

  return aBalance - bBalance
}

export const statusCompare: ViolationSort = (a: Violation, b: Violation) => {
  const aStatus = getStatusType(a)
  const bStatus = getStatusType(b)

  return aStatus.localeCompare(bStatus)
}

const getSortFunc = (type: ViolationSortType | undefined): ViolationSort => {
  switch (type) {
    case ViolationSortType.balance: {
      return balanceCompare
    }
    case ViolationSortType.hearingDate: {
      return hearingDateCompare
    }
    case ViolationSortType.status: {
      return statusCompare
    }
    default: {
      return nosort
    }
  }
}

export const getSort = (
  type: ViolationSortType | undefined,
  reverse = false
): ViolationSort => {
  const sort = getSortFunc(type)
  return reverse ? reverseCompare(sort) : sort
}

export const getSortInfo = (type: ViolationSortType) => (
  prevInfo: SortInfo<ViolationSortType>
): SortInfo<ViolationSortType> => ({
  type,
  reversed: prevInfo.type === type && !prevInfo.reversed ? true : false,
})

export const emptySort: ViolationSortInfo = {
  order_by: undefined,
  order_by_direction: undefined,
}

export const emptyFilter: ViolationFilters = violationsFilterTypes.reduce(
  (acc, filter) => ({
    ...acc,
    [filter]: undefined,
  }),
  {}
)

export const emptyInfCodesFilter: ViolationInfractionCodesFilters = violationsInfCodesFilterTypes.reduce(
  (acc, filter) => ({
    ...acc,
    [filter]: undefined,
  }),
  {}
)

const _mergeViolations = (a: Violations, b: Violations): Violations => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return [...a, ...b]
  }
  if (Array.isArray(a)) {
    a = { [Object.keys(b).length]: a }
  }
  if (Array.isArray(b)) {
    b = { [Object.keys(a).length]: b }
  }

  const inter = intersection(Object.keys(a), Object.keys(b)).reduce(
    (acc, key) => ({
      ...acc,
      [key]: [
        ...(a as GroupedViolations)[key],
        ...(b as GroupedViolations)[key],
      ].filter(
        (value, index, self) =>
          index ===
          self.findIndex((searchValue) => objectsAreEqual(value, searchValue))
      ),
    }),
    {}
  )

  return { ...a, ...b, ...inter }
}

export const mergeViolations = (...args: Violations[]): Violations =>
  args.reduce((acc, violations) => _mergeViolations(acc, violations))

export default {
  getStatusType,
  emptySort,
  emptyFilter,
  hearingDateCompare,
  balanceCompare,
  statusCompare,
  getSort,
  getSortInfo,
  mergeViolations,
}
